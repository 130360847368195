import { configureStore } from '@reduxjs/toolkit'
import type { TypedUseSelectorHook } from 'react-redux';
import {
  useSelector as useReduxSelector
} from 'react-redux';

import authReducer from "./slices/authSlice";
import logReducer from './slices/logSlice';
import listReducer from './slices/listSlice';
import unknownReducer from './slices/unknownSlice';
import intellectualPropertyReducer from './slices/intellectualPropertySlice';
import commentReducer from './slices/commentSlice';
import documentReducer from './slices/documentSlice';
import moveReducer from './slices/moveSlice';
import moveSimpleCostingReducer from './slices/moveSliceSimpleCosting';
import stakeholderReducer from './slices/stakeholderSlice';
import factorReducer from './slices/factorSlice';
import bugReportReducer from './slices/bugReportSlice';

import review1Reducer from './slices/review1Slice';
import review2Reducer from './slices/review2Slice';
import review3Reducer from './slices/review3Slice';
import review4Reducer from './slices/review4Slice';
import reportsReducer from './slices/reportsSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    log: logReducer,
    list: listReducer,
    unknown: unknownReducer,
    intellectualProperty: intellectualPropertyReducer,
    comment: commentReducer,
    document: documentReducer,
    move: moveReducer,
    moveSimpleCosting: moveSimpleCostingReducer,
    stakeholder: stakeholderReducer,
    factor: factorReducer,
    bugReport: bugReportReducer,
    review1: review1Reducer,
    review2: review2Reducer,
    review3: review3Reducer,
    review4: review4Reducer,
    reports: reportsReducer
  }
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export default store