import { FC, useEffect, useRef } from 'react';
import { useState } from 'react';
import PropTypes, { element } from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  MenuItem,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Chip,
  ButtonGroup
} from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import type { Customer } from '../../types/customer';
import { InputField } from '../input-field';
import { AutocompleteField } from '../autocomplete-field';
import { customerPostOne, customerPutOne, customerDeleteOne } from '../../api/review-1/review-1-customer';
import { XCircle as XCircleIcon } from '../../icons/x-circle';
import { ConfirmationDialog }  from '../confirmation-dialog';
import { concernings } from '../../static-data';
import { review3GetAll } from '../../slices/review3Slice';
import { factorPost, resetFactorStatus, factorDelete } from '../../slices/factorSlice';
import { RootState } from '../../store';
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import { InfoPop } from '../info-pop';
import { PopoverConcerning } from '../popover-concerning';
import { openFactorDialog } from 'src/slices/factorSlice';
import { ChevronLeft as ChevronLeftIcon } from '../../icons/chevron-left';
import { ChevronRight as ChevronRightIcon } from '../../icons/chevron-right';
import { factorGetAll } from 'src/api/factor';
import { TableItemMenu } from '../table-item-menu';

interface FactorDialogProps {
  open: boolean;
  onClose: () => void;
}

export const FactorDialog: FC<FactorDialogProps> = (props) => {
  const { t } = useTranslation();
  const { open, onClose, ...other } = props;
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  // const { factorIsLoading, factorStatus, factorNextAction } = useSelector((state: RootState) => state.factor);
  const factor = useSelector((state: RootState) => state.factor);
  const {factors} = useSelector((state: RootState) => state.review3);
  const [showDetails, setShowDetails] = useState(true);
  const [showList, setShowList] = useState(true);

  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchedString, setSearchedString] = useState('');

  const [height, setHeight] = useState(0)
  const mainGridRef = useRef(null)
  const [refVisible, setRefVisible] = useState(false)

  const anchorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!refVisible) {
      return
    }
    setHeight(mainGridRef.current.clientHeight)
  }, [refVisible, showDetails, mainGridRef?.current?.clientHeight])

  useEffect(() => {
    getItems();
  }, []);

  useEffect(() => {
    getItems();
  }, [searchedString]);

  const getItems = async () => {
    const res = await factorGetAll('description', 'asc', searchedString, 1, 1000);
    console.log(res);
    if (res.items) {
      setItems(res.items);
      setTotal(res.total);
    } else {
      setItems([]);
      setTotal(0);
    }
  };

  const dispatch = useDispatch();

  let initialValues = {
    description: '',
    comment: '',
    concernings: [],
    positiveImpact: -1,
    negativeImpact: -1,
    opportunityKiller: 0,
    probability: 0,
    preMove: '',
    contMove: '',
    order: null,
    submit: null
  };

  if ((factor.factorId !== null && factor.factorId !== undefined) || factor.isDuplicated === true) {
    console.log('factor id', factor.factorId);
    initialValues.description = factor.description;
    initialValues.comment = factor.comment;
    initialValues.concernings = factor.concernings;
    initialValues.positiveImpact = factor.positiveImpact;
    initialValues.negativeImpact = factor.negativeImpact;
    initialValues.opportunityKiller = factor.opportunityKiller;
    initialValues.probability = factor.probability;
    initialValues.preMove = factor.preMove;
    initialValues.contMove = factor.contMove;
  }

  useEffect(() => {
    console.log('store status', factor.factorIsLoading);
    if (factor.factorStatus === 'success') {
      toast.success(`Factor created`);
      dispatch(review3GetAll());
      formik.resetForm();
      formik.setStatus({ success: true });
      formik.setSubmitting(false);
      dispatch(resetFactorStatus());
      if (factor.factorNextAction === 'close') {
        onClose();
      }
    } else {
      formik.setStatus({ success: false });
      formik.setErrors({ submit: factor.factorStatus });
      formik.setSubmitting(false);
    }
  }, [factor.factorStatus]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      description: Yup.string().min(3).max(255).required('Description is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        // dispatch(review1PostCustomer(values));
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  const handlePositiveImpact = (event: React.MouseEvent<HTMLElement>, newAlignment: number | null) => {
    formik.setFieldValue('positiveImpact', newAlignment);
  };

  const handleNegativeImpact = (event: React.MouseEvent<HTMLElement>, newAlignment: number | null) => {
    formik.setFieldValue('negativeImpact', newAlignment);
  };

  const handleOpportunityKiller = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setChecked([checked[0], event.target.checked]);
    formik.setFieldValue('opportunityKiller', event.target.checked);
  };

  const handleSubmitAndQuit = () => {
    console.log('Submit and quit');
    dispatch(factorPost('close', formik.values, factor.factorId));
  };

  const handleSubmitAndReset = () => {
    console.log('Submit and reset');
    dispatch(factorPost('new', formik.values));
  };

  const handleUpdateConcernings = (newConcernings: any[]) => {
    formik.setFieldValue('concernings', newConcernings);
  };

  return (
    <Dialog
      maxWidth="xl"
      fullWidth
      onClose={() => {formik.values.description !== '' ? setOpenConfirmationDialog(true) : onClose();}}
      open={open}
      TransitionProps={{
        onExited: () => formik.resetForm()
      }}
      PaperProps={{
        style: { borderRadius: 25 }
      }}
      {...other}
    >
      {/* <DialogTitle>
        {(factor.factorId !== null && factor.factorId !== undefined) ? `${t('update_factor')} : ${factor.description}` : t('create_factor')}
        <div style={{ display: 'inline-block'}}>
          <InfoPop
            infoElementName={t('factors')}
            shortText='What is a Factor?'
            position='right'
            longText='Factors are those events, circumstances or conditions generally outside your sphere of influence that can influence the ability to achieve the Definition of Success. <br />
            Boosters are Factors that can contribute to success, while Hurdles are those that will slow down the Project or even block it (Obstacles). <br />
            Apart from the Factors whose probability is certain (probability = 100%), the other Factors have a probability of occurrence that is less than 100%. <br />
            To indicate the level of probability of uncertain Factors, they can be grouped as "low probability", "medium probability" or "high probability".<br />
            Risks are Hurdles with a probability of occurrence of less than 100%.'
          />
        </div>
      </DialogTitle> */}
      <DialogContent sx={{ padding: 0, backgroundColor: "background.default" }}>
        <Grid
          container
          spacing={2}
          // my={2}
        >
          <Grid item xs={showList ? 8 : 12} sx={{ p: 0, backgroundColor: "background.default" }}>
            <Grid container spacing={2} padding={4} sx={{ overflowY: "scroll" }} maxHeight={'80vh'} ref={el => {mainGridRef.current = el; setRefVisible(!!el);}}>
              <Grid item xs={12}>
                <Typography variant="h5" color={'warning.main'}>
                {(factor.factorId !== null && factor.factorId !== undefined) ? `${t('update_factor')} : ${factor.description}` : t('create_factor')}
                  <div style={{ display: 'inline-block'}}>
                    <InfoPop
                      infoElementName={t('unknowns')}
                      shortText='What is a Unknown?'
                      position='right'
                      longText='Unknowns exist when information or knowledge are missing. Once the missing information has been obtained, the Unknown no longer exists. Since Unknowns are only testifying of your ignorance, they have no probability of occurrence like it is the case for Risks.<br />
                      Unknowns can relate to Customers, technology, market, regulations, Stakeholders, organizational issues,Resources, etc.<br />
                      Once Unknowns have been identified, you can figure out the effort (time, money, people, etc.) needed to obtain the missing information/knowledge, which will eventually reduce the Unknowns. You should make sure to reduce Unknowns in the optimal sequence that consumes the least amount of resources (money, time, etc.). <br />
                      To optimize the sequence you should establis an as complete as possible inventory of all identifiable Unknowns. <br />
                      Any Unknown that has not been identified reduces your ability to realistically assess the effort required to reduce Unknowns and/or deliver the Definition of Success.'
                    />
                  </div>
                  <div style={{ display: 'inline-block', textAlign: 'right', float: 'right' }}>
                  <IconButton onClick={() => setShowList(!showList)}>
                    {showList ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                  </IconButton>
                  </div>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
              >
                <InputField
                  error={Boolean(formik.touched.description && formik.errors.description)}
                  fullWidth
                  helperText={formik.touched.description && formik.errors.description}
                  label={t('description')}
                  name="description"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  type="description"
                  value={formik.values.description}
                  multiline
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
              >
                <span><b>Concerning</b></span>
                <div style={{ width: '100%', backgroundColor: '#EEE', padding: 5, borderRadius: 5, fontSize: 10, cursor: 'pointer', marginTop: '2px', overflowX: 'scroll' }}>
                  <PopoverConcerning existingConcernings={formik.values.concernings} onUpdateConcernings={handleUpdateConcernings} />
                </div>
                {/* <InputField
                  error={Boolean(formik.touched.concerningId && formik.errors.concerningId)}
                  fullWidth
                  helperText={formik.touched.concerningId && formik.errors.concerningId}
                  label={t('concerning')}
                  name="concerningId"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  type="concerningId"
                  value={formik.values.concerningId}
                  select
                >
                  {Object.keys(concernings).map((k) => (
                    <MenuItem key={`concerning-${k}`} value={k}>{t(concernings[k]['label'])}</MenuItem>
                  ))}
                </InputField> */}
              </Grid>
              <Grid item xs={12}>
                <InputField
                  error={Boolean(formik.touched.comment && formik.errors.comment)}
                  fullWidth
                  helperText={formik.touched.comment && formik.errors.comment}
                  label={t('Comments about this Factor')}
                  name="comment"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="comment"
                  value={formik.values.comment}
                  multiline
                  rows={2}
                  // sx={{'& .MuiFilledInput-root': {borderColor: 'red'}}}
                />
              </Grid>
              {/* <Grid item xs={12} textAlign="center">
                <Button
                  onClick={() => setShowDetails(!showDetails)}
                  type="button"
                  color={'warning'}
                >
                  {showDetails === true ? t('hide_details') : t('show_details')}
                </Button>
              </Grid> */}
              {(showDetails === true) ? (
                <>
                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <Typography
                      variant='body1'
                    >
                      {t('positive_impact')}
                    </Typography>
                    <ToggleButtonGroup
                      value={formik.values.positiveImpact}
                      // onChange={formik.handleChange}
                      id="positiveImpact"
                      exclusive
                      fullWidth
                      size='small'
                      onChange={handlePositiveImpact}
                      aria-label="text alignment"
                    >
                      <ToggleButton value={-1} id="positiveImpact" aria-label="right aligned" sx={{ '&.Mui-selected': {backgroundColor: 'background.paper', color: 'text.primary'}, padding: 0.25, fontSize: "10px", borderTopLeftRadius: '100px', borderBottomLeftRadius: '100px' }}>
                        ?
                      </ToggleButton>
                      <ToggleButton value={0} id="positiveImpact" aria-label="left aligned" sx={{ '&.Mui-selected': {backgroundColor: '#D2F2CD', color: '#49CC36'}, padding: 0.25, fontSize: "10px" }}>
                        {/* <FormatAlignLeftIcon /> */}
                        0
                      </ToggleButton>
                      <ToggleButton value={1} id="positiveImpact" aria-label="left aligned" sx={{ '&.Mui-selected': {backgroundColor: '#A4E59B', color: '#FFF'}, padding: 0.25, fontSize: "10px" }}>
                        {/* <FormatAlignLeftIcon /> */}
                        +
                      </ToggleButton>
                      <ToggleButton value={2} id="positiveImpact" aria-label="centered" sx={{ '&.Mui-selected': {backgroundColor: '#77D968', color: '#FFF'}, padding: 0.25, fontSize: "10px" }}>
                        {/* <FormatAlignCenterIcon /> */}
                        + +
                      </ToggleButton>
                      <ToggleButton value={3} id="positiveImpact" aria-label="right aligned" sx={{ '&.Mui-selected': {backgroundColor: '#49CC36', color: '#FFF'}, padding: 0.25, fontSize: "10px", borderTopRightRadius: '100px', borderBottomRightRadius: '100px' }}>
                        {/* <FormatAlignRightIcon /> */}
                        + + +
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <Typography
                      variant='body1'
                    >
                      {t('negative_impact')}
                    </Typography>
                    <ToggleButtonGroup
                      value={formik.values.negativeImpact}
                      // onChange={formik.handleChange}
                      id="positiveImpact"
                      exclusive
                      fullWidth
                      size='small'
                      onChange={handleNegativeImpact}
                      aria-label="text alignment"
                    >
                      <ToggleButton value={-1} id="negativeImpact" aria-label="right aligned" sx={{ '&.Mui-selected': {backgroundColor: 'background.paper', color: 'text.primary'}, padding: 0.25, fontSize: "10px", borderTopLeftRadius: '100px', borderBottomLeftRadius: '100px' }}>
                        ?
                      </ToggleButton>
                      <ToggleButton value={0} id="negativeImpact" aria-label="left aligned" sx={{ '&.Mui-selected': {backgroundColor: '#FFD3CC', color: '#FF4E33'}, padding: 0.25, fontSize: "10px" }}>
                        {/* <FormatAlignLeftIcon /> */}
                        0
                      </ToggleButton>
                      <ToggleButton value={1} id="negativeImpact" aria-label="left aligned" sx={{ '&.Mui-selected': {backgroundColor: '#FFA799', color: '#FFF'}, padding: 0.25, fontSize: "10px" }}>
                        {/* <FormatAlignLeftIcon /> */}
                        -
                      </ToggleButton>
                      <ToggleButton value={2} id="negativeImpact" aria-label="centered" sx={{ '&.Mui-selected': {backgroundColor: '#FF7A66', color: '#FFF'}, padding: 0.25, fontSize: "10px" }}>
                        {/* <FormatAlignCenterIcon /> */}
                        - -
                      </ToggleButton>
                      <ToggleButton value={3} id="negativeImpact" aria-label="right aligned" sx={{ '&.Mui-selected': {backgroundColor: '#FF4E33', color: '#FFF'}, padding: 0.25, fontSize: "10px", borderTopRightRadius: '100px', borderBottomRightRadius: '100px' }}>
                        {/* <FormatAlignRightIcon /> */}
                        - - -
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  {/* <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <FormGroup>
                      <FormControlLabel control={<Checkbox checked={formik.values.opportunityKiller} onChange={handleOpportunityKiller} />} label={t('opportunity_killer')} />
                    </FormGroup>
                  </Grid> */}
                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <InputField
                      error={Boolean(formik.touched.opportunityKiller && formik.errors.opportunityKiller)}
                      fullWidth
                      helperText={formik.touched.opportunityKiller && formik.errors.opportunityKiller}
                      label={t('opportunity_killer')}
                      name="opportunityKiller"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      required
                      value={formik.values.opportunityKiller}
                      select
                    >
                      <MenuItem value={1}>{t('opportunity_killer')}</MenuItem>
                      <MenuItem value={2}>{t('critical')}</MenuItem>
                      <MenuItem value={3}>{t('important')}</MenuItem>
                      <MenuItem value={4}>{t('minor')}</MenuItem>
                      <MenuItem value={0}>?</MenuItem>
                    </InputField>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <InputField
                      error={Boolean(formik.touched.probability && formik.errors.probability)}
                      fullWidth
                      helperText={formik.touched.probability && formik.errors.probability}
                      label={t('probability')}
                      name="probability"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      required
                      type="probability"
                      value={formik.values.probability}
                      select
                    >
                      <MenuItem value={1}>{t('low')}</MenuItem>
                      <MenuItem value={2}>{t('medium')}</MenuItem>
                      <MenuItem value={3}>{t('high')}</MenuItem>
                      <MenuItem value={4}>{t('certain')}</MenuItem>
                      <MenuItem value={5}>{t('for_sure')}</MenuItem>
                      <MenuItem value={0}>?</MenuItem>
                    </InputField>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <span style={{
                      fontSize: 12,
                      fontWeight: 500,
                      position: 'relative',
                      transform: 'none',
                      // textDecoration: 'line-through'
                    }}>{t('preventive_move')}</span>
                    <div style={{ display: 'inline-block'}}>
                      <InfoPop
                        infoElementName={t('preventive_move')}
                        shortText='A preventive Move is a Tactical Move intended to prevent the occurrence of a Hurdle (negative Factor) or Risk; or increase the occurrence of a Booster (positive Factor)'
                        position='right'
                      />
                    </div>
                    <InputField
                      error={Boolean(formik.touched.preMove && formik.errors.preMove)}
                      fullWidth
                      helperText={formik.touched.preMove && formik.errors.preMove}
                      // label={t('preventive_move')}
                      name="preMove"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="description"
                      // value={formik.values.preMove}
                      value={(factor.factorId !== null && factor.factorId !== undefined && factor.preMove !== null) ? factor.preMove?.name : formik.values.preMove}
                      multiline
                      disabled={(factor.factorId !== null && factor.factorId !== undefined) && (factor.preMove !== null)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <span style={{
                      fontSize: 12,
                      fontWeight: 500,
                      position: 'relative',
                      transform: 'none',
                      // textDecoration: 'line-through'
                    }}>{t('contigency_move')}</span>
                    <div style={{ display: 'inline-block'}}>
                      <InfoPop
                        infoElementName={t('contigency_move')}
                        shortText='A contingency Move is a Tactical Move intended to limit/reduce the impact of a Hurdle (negative) Factor or take advantage of aBooster (positive Factor).'
                        position='right'
                      />
                    </div>
                    <InputField
                      error={Boolean(formik.touched.contMove && formik.errors.contMove)}
                      fullWidth
                      helperText={formik.touched.contMove && formik.errors.contMove}
                      // label={t('contigency_move')}
                      name="contMove"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="description"
                      // value={formik.values.contMove}
                      value={(factor.factorId !== null && factor.factorId !== undefined && factor.contMove !== null) ? factor.contMove?.name : formik.values.contMove}
                      multiline
                      disabled={(factor.factorId !== null && factor.factorId !== undefined) && (factor.contMove !== null)}
                    />
                  </Grid>
                  {formik.errors.submit && (
                    <Grid
                      item
                      xs={12}
                    >
                      <FormHelperText error>
                        {formik.errors.submit}
                      </FormHelperText>
                    </Grid>
                  )}
                </>) : <></>
              }
              <Grid><Divider /></Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  color="warning"
                  size="small"
                  onClick={onClose}
                  variant="outlined"
                  sx={{ mx: 1}}
                >
                  {t('cancel')}
                </Button>
                <Button
                  color="warning"
                  size="small"
                  disabled={(factor.factorIsLoading || !factor.isEditabled || (formik.values.description.length < 3))}
                  onClick={() => { handleSubmitAndQuit(); }}
                  variant="contained"
                  sx={{ mx: 1}}
                >
                  {(factor.factorId !== null && factor.factorId !== undefined) ? t('update') : t('create')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          { showList && (
              <Grid item xs={4} sx={{ backgroundColor: "rgba(255, 123, 0, 0.25)", overflowY: "scroll" }} padding={0} spacing={2} height={height}>
                <Grid container spacing={2} padding={4} sx={{paddingLeft: 2}}>
                  <Grid item xs={12} sx={{ paddingLeft: 0 }}>
                    <Typography variant="h5" style={{ color: 'rgba(255, 123, 0, 1)' }}>
                      All factors <Chip color='default' label={total} size='small' variant='filled' />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ paddingLeft: 0 }}>
                    <Button variant='contained' size='small' color='warning' fullWidth onClick={() => dispatch(openFactorDialog({ element: {id: null}, isEditabled: true, isDuplicated: false }))}>
                      New factor
                    </Button>
                  </Grid>
                  <Grid item xs={12} sx={{ paddingLeft: 0 }}>
                    <InputField
                      fullWidth
                      placeholder='Search for a factor'
                      name="search"
                      onChange={(e) => setSearchedString(e.target.value)}
                      type="text"
                      value={searchedString}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <List>
                      {items?.map((u) => (
                        <ListItem disablePadding sx={{ backgroundColor: "background.default", marginBottom: 1, borderRadius: 1, padding: 0, fontSize: "10px" }}>
                          <ButtonGroup
                            variant="contained"
                            ref={anchorRef}
                            aria-label="Button group with a nested menu"
                            fullWidth
                            sx={{ padding: 0, fontSize: "10px", backgroundColor: 'white' }}
                            color='inherit'
                          >
                            <Button onClick={() => dispatch(openFactorDialog({ element: u, isEditabled: true, isDuplicated: false }))} sx={{ backgroundColor: 'white', padding: '10px', textAlign: 'left' }}><span style={{ padding: '10px', width: '100%' }}>{u.description}</span></Button>
                            <Button
                              size="small"
                              aria-controls={open ? 'split-button-menu' : undefined}
                              aria-expanded={open ? 'true' : undefined}
                              aria-label="select merge strategy"
                              aria-haspopup="menu"
                              style={{ width: '1%' }}
                              sx={{ backgroundColor: 'white' }}
                            // onClick={handleToggle}
                            >
                              {/* <FontAwesomeIcon icon={faEllipsis as IconProp} /> */}
                              <TableItemMenu
                                onView={() => dispatch(openFactorDialog({element: u, isEditabled: false, isDuplicated: false}))}
                                onEdit={() => dispatch(openFactorDialog({element: u, isEditabled: true, isDuplicated: false}))}
                                onDuplicate={() => dispatch(openFactorDialog({element: u, isEditabled: true, isDuplicated: true}))}
                                onDelete={async () => {
                                  await dispatch(factorDelete(u.id));
                                  getItems();
                                }}
                              />
                            </Button>
                          </ButtonGroup>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </Grid>
            )}
        </Grid>
      </DialogContent>
      <Divider />
      {/* <DialogActions>
        <Button
          color="primary"
          size="small"
          onClick={onClose}
          variant="outlined"
        >
          {t('cancel')}
        </Button>
        <Button
          color="success"
          size="small"
          disabled={(factor.factorIsLoading || !factor.isEditabled)}
          onClick={() => { handleSubmitAndQuit(); }}
          variant="contained"
        >
          {(factor.factorId !== null && factor.factorId !== undefined) ? t('update') : t('create')}
        </Button>
      </DialogActions> */}
      <ConfirmationDialog
        message={t('do_you_want_to_close_without_saving')}
        onCancel={() => {setOpenConfirmationDialog(false)}}
        onConfirm={() => {
          setOpenConfirmationDialog(false);
          onClose();
        }}
        open={openConfirmationDialog}
        title={t('confirmation_title')}
        variant='warning'
      />
    </Dialog>
  );
};

FactorDialog.defaultProps = {
  open: false
};

FactorDialog.propTypes = {
  // @ts-ignore
  open: PropTypes.bool,
  onClose: PropTypes.func
};
