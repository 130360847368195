import { FC, useEffect, useRef } from 'react';
import { useState } from 'react';
import PropTypes, { element } from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  MenuItem,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Chip,
  ButtonGroup
} from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import type { Customer } from '../../types/customer';
import { InputField } from '../input-field';
import { AutocompleteField } from '../autocomplete-field';
import { customerPostOne, customerPutOne, customerDeleteOne } from '../../api/review-1/review-1-customer';
import { XCircle as XCircleIcon } from '../../icons/x-circle';
import { ConfirmationDialog } from '../confirmation-dialog';
import { concernings } from '../../static-data';
import { unknownPost, resetUnknownStatus, unknownDelete } from '../../slices/unknownSlice';
import { review3GetAll } from '../../slices/review3Slice';
import { RootState } from '../../store';
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import { InfoPop } from '../info-pop';
import { PopoverConcerning } from '../popover-concerning';
import { openUnknownDialog } from 'src/slices/unknownSlice';
import { ChevronLeft as ChevronLeftIcon } from '../../icons/chevron-left';
import { ChevronRight as ChevronRightIcon } from '../../icons/chevron-right';
import { unknownGetAll } from 'src/api/unknown';
import { TableItemMenu } from '../table-item-menu';

interface UnknownDialogProps {
  open: boolean;
  onClose: () => void;
}

export const UnknownDialog: FC<UnknownDialogProps> = (props) => {
  const { t } = useTranslation();
  const { open, onClose, ...other } = props;
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const { unknownIsLoading, unknownStatus, unknownNextAction } = useSelector((state: RootState) => state.unknown);
  const [showDetails, setShowDetails] = useState(true);
  const [showList, setShowList] = useState(true);

  const [height, setHeight] = useState(0)
  const mainGridRef = useRef(null)
  const [refVisible, setRefVisible] = useState(false)

  const anchorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!refVisible) {
      return
    }
    setHeight(mainGridRef.current.clientHeight)
  }, [refVisible, showDetails, mainGridRef?.current?.clientHeight])

  const unknown = useSelector((state: RootState) => state.unknown);
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchedString, setSearchedString] = useState('');

  useEffect(() => {
    getItems();
  }, []);

  useEffect(() => {
    getItems();
  }, [searchedString]);

  const getItems = async () => {
    const res = await unknownGetAll('description', 'asc', searchedString, 1, 1000);
    console.log(res);
    if (res.items) {
      setItems(res.items);
      setTotal(res.total);
    } else {
      setItems([]);
      setTotal(0);
    }
  };

  let initialValues = {
    description: '',
    comment: '',
    outcome: '',
    concernings: [],
    positive_impact: -1,
    negative_impact: -1,
    no_go: 0,
    move: '',
    submit: null
  };

  if ((unknown.unknownId !== null && unknown.unknownId !== undefined) || unknown.isDuplicated === true) {
    initialValues.description = unknown.description;
    initialValues.comment = unknown.comment;
    initialValues.outcome = unknown.outcome;
    initialValues.concernings = unknown.concernings;
    initialValues.positive_impact = unknown.positive_impact;
    initialValues.negative_impact = unknown.negative_impact;
    initialValues.no_go = unknown.no_go;
    initialValues.move = unknown.move;
  }

  useEffect(() => {
    console.log('store status', unknownIsLoading);
    if (unknownStatus === 'success') {
      toast.success(`Unknown ${(unknown.unknownId !== null && unknown.unknownId !== undefined) ? 'updated' : 'created'}`);
      formik.resetForm();
      formik.setStatus({ success: true });
      formik.setSubmitting(false);
      dispatch(resetUnknownStatus());
      getItems();
      dispatch(review3GetAll());
      if (unknownNextAction === 'close') {
        onClose();
      }
    } else {
      formik.setStatus({ success: false });
      formik.setErrors({ submit: unknownStatus });
      formik.setSubmitting(false);
    }
  }, [unknownStatus]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      description: Yup.string().min(3).max(255).required('Description is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        // dispatch(review1PostCustomer(values));
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  const handlePositiveImpact = (event: React.MouseEvent<HTMLElement>, newAlignment: number | null) => {
    formik.setFieldValue('positive_impact', newAlignment);
  };

  const handleNegativeImpact = (event: React.MouseEvent<HTMLElement>, newAlignment: number | null) => {
    formik.setFieldValue('negative_impact', newAlignment);
  };

  const handleNoGo = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setChecked([checked[0], event.target.checked]);
    formik.setFieldValue('no_go', event.target.checked);
  };

  const handleSubmitAndQuit = () => {
    console.log('Submit and quit');
    dispatch(unknownPost('close', formik.values, unknown.unknownId));
  };

  const handleSubmitAndReset = () => {
    console.log('Submit and reset');
    dispatch(unknownPost('new', formik.values));
  };

  const handleUpdateConcernings = (newConcernings: any[]) => {
    formik.setFieldValue('concernings', newConcernings);
  };

  return (
    <Dialog
      maxWidth="xl"
      fullWidth
      onClose={() => { formik.values.description !== '' ? setOpenConfirmationDialog(true) : onClose(); }}
      open={open}
      TransitionProps={{
        onExited: () => formik.resetForm()
      }}
      PaperProps={{
        style: { borderRadius: 25 }
      }}
      {...other}
    >
      {/* <DialogTitle>
        {(unknown.unknownId !== null && unknown.unknownId !== undefined) ? `${t('update_unknown')} : ${unknown.description}` : t('create_unknown')}
        <div style={{ display: 'inline-block'}}>
          <InfoPop
            infoElementName={t('unknowns')}
            shortText='What is a Unknown?'
            position='right'
            longText='Unknowns exist when information or knowledge are missing. Once the missing information has been obtained, the Unknown no longer exists. Since Unknowns are only testifying of your ignorance, they have no probability of occurrence like it is the case for Risks.<br />
            Unknowns can relate to Customers, technology, market, regulations, Stakeholders, organizational issues,Resources, etc.<br />
            Once Unknowns have been identified, you can figure out the effort (time, money, people, etc.) needed to obtain the missing information/knowledge, which will eventually reduce the Unknowns. You should make sure to reduce Unknowns in the optimal sequence that consumes the least amount of resources (money, time, etc.). <br />
            To optimize the sequence you should establis an as complete as possible inventory of all identifiable Unknowns. <br />
            Any Unknown that has not been identified reduces your ability to realistically assess the effort required to reduce Unknowns and/or deliver the Definition of Success.'
          />
        </div>
      </DialogTitle> */}
      <DialogContent sx={{ padding: 0 }}>
        <Grid
          container
          spacing={2}
        // my={2}
        >
          <Grid item xs={showList ? 8 : 12} sx={{ p: 0, backgroundColor: "background.default" }}>
            <Grid container spacing={2} padding={4} sx={{ overflowY: "scroll" }} maxHeight={'80vh'} ref={el => { mainGridRef.current = el; setRefVisible(!!el); }}>
              <Grid item xs={12}>
                <Typography variant="h5" color={'error.main'}>
                  {(unknown.unknownId !== null && unknown.unknownId !== undefined) ? `${t('update_unknown')} : ${unknown.description}` : t('create_unknown')}
                  <div style={{ display: 'inline-block' }}>
                    <InfoPop
                      infoElementName={t('unknowns')}
                      shortText='What is a Unknown?'
                      position='right'
                      longText='Unknowns exist when information or knowledge are missing. Once the missing information has been obtained, the Unknown no longer exists. Since Unknowns are only testifying of your ignorance, they have no probability of occurrence like it is the case for Risks.<br />
                      Unknowns can relate to Customers, technology, market, regulations, Stakeholders, organizational issues,Resources, etc.<br />
                      Once Unknowns have been identified, you can figure out the effort (time, money, people, etc.) needed to obtain the missing information/knowledge, which will eventually reduce the Unknowns. You should make sure to reduce Unknowns in the optimal sequence that consumes the least amount of resources (money, time, etc.). <br />
                      To optimize the sequence you should establis an as complete as possible inventory of all identifiable Unknowns. <br />
                      Any Unknown that has not been identified reduces your ability to realistically assess the effort required to reduce Unknowns and/or deliver the Definition of Success.'
                    />
                  </div>
                  <div style={{ display: 'inline-block', textAlign: 'right', float: 'right' }}>
                    <IconButton onClick={() => setShowList(!showList)}>
                      {showList ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                  </div>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
              >
                <InputField
                  error={Boolean(formik.touched.description && formik.errors.description)}
                  fullWidth
                  helperText={formik.touched.description && formik.errors.description}
                  label={t('Description of the Unknown ')}
                  name="description"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  type="description"
                  value={formik.values.description}
                  multiline
                // sx={{'& .MuiFilledInput-root': {borderColor: 'red'}}}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
              >
                {/* <span><b>Concernings</b></span> */}
                <Typography
                  variant='body1'
                  sx={{ mb: 0.5 }}
                >
                  {t('Concerning')}
                </Typography>
                <div style={{ width: '100%', backgroundColor: '#EEE', padding: 3, borderRadius: 5, fontSize: 10, cursor: 'pointer', marginTop: '0px', overflowX: 'scroll', lineHeight: 1.6 }}>
                  <PopoverConcerning existingConcernings={formik.values.concernings} onUpdateConcernings={handleUpdateConcernings} />
                </div>
                {/* <InputField
                  error={Boolean(formik.touched.concerningId && formik.errors.concerningId)}
                  fullWidth
                  helperText={formik.touched.concerningId && formik.errors.concerningId}
                  label={t('concerning')}
                  name="concerningId"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  type="concerningId"
                  value={formik.values.concerningId}
                  select
                >
                  {Object.keys(concernings).map((k) => (
                    <MenuItem key={`concerning-${k}`} value={k}>{t(concernings[k]['label'])}</MenuItem>
                  ))}
                </InputField> */}
              </Grid>
              <Grid item xs={12}>
                <InputField
                  error={Boolean(formik.touched.comment && formik.errors.comment)}
                  fullWidth
                  helperText={formik.touched.comment && formik.errors.comment}
                  label={t('Comments about this Unknown')}
                  name="comment"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="comment"
                  value={formik.values.comment}
                  multiline
                // minRows={1}
                // sx={{'& .MuiFilledInput-root': {borderColor: 'red'}}}
                />
              </Grid>
              {/* <Grid item xs={12} textAlign="center">
                <Button
                  onClick={() => setShowDetails(!showDetails)}
                  type="button"
                  sx={{ color: 'error.main' }}
                >
                  {showDetails === true ? t('hide_details') : t('show_details')}
                </Button>
              </Grid> */}
              {(showDetails === true) ? (
                <>
                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <Typography
                      variant='body1'
                      sx={{ mb: 0.5 }}
                    >
                      {t('Potential positive impact')}
                    </Typography>
                    <ToggleButtonGroup
                      value={formik.values.positive_impact}
                      // onChange={formik.handleChange}
                      id="positive_impact"
                      exclusive
                      fullWidth
                      size='small'
                      onChange={handlePositiveImpact}
                      aria-label="text alignment"
                    >
                      <ToggleButton value={-1} id="positive_impact" aria-label="right aligned" sx={{ '&.Mui-selected': { backgroundColor: 'background.paper', color: 'text.primary' }, padding: 0.25, fontSize: "14px", borderTopLeftRadius: '100px', borderBottomLeftRadius: '100px' }}>
                        ?
                      </ToggleButton>
                      <ToggleButton value={0} id="positive_impact" aria-label="left aligned" sx={{ '&.Mui-selected': { backgroundColor: '#D2F2CD', color: '#49CC36' }, '&:hover': { backgroundColor: '#D2F2CD', color: '#49CC36' }, padding: 0.25, fontSize: "14px" }}>
                        {/* <FormatAlignLeftIcon /> */}
                        0
                      </ToggleButton>
                      <ToggleButton value={1} id="positive_impact" aria-label="left aligned" sx={{ '&.Mui-selected': { backgroundColor: '#A4E59B', color: '#FFF' }, '&:hover': { backgroundColor: '#A4E59B', color: '#FFF' }, padding: 0.25, fontSize: "14px" }}>
                        {/* <FormatAlignLeftIcon /> */}
                        +
                      </ToggleButton>
                      <ToggleButton value={2} id="positive_impact" aria-label="centered" sx={{ '&.Mui-selected': { backgroundColor: '#77D968', color: '#FFF' }, '&:hover': { backgroundColor: '#77D968', color: '#FFF' }, padding: 0.25, fontSize: "14px" }}>
                        {/* <FormatAlignCenterIcon /> */}
                        + +
                      </ToggleButton>
                      <ToggleButton value={3} id="positive_impact" aria-label="right aligned" sx={{ '&.Mui-selected': { backgroundColor: '#49CC36', color: '#FFF' }, '&:hover': { backgroundColor: '#49CC36', color: '#FFF' }, padding: 0.25, fontSize: "14px", borderTopRightRadius: '100px', borderBottomRightRadius: '100px' }}>
                        {/* <FormatAlignRightIcon /> */}
                        + + +
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <Typography
                      variant='body1'
                      sx={{ mb: 0.5 }}
                    >
                      {t('Potential negative impact')}
                    </Typography>
                    <ToggleButtonGroup
                      value={formik.values.negative_impact}
                      // onChange={formik.handleChange}
                      id="negative_impact"
                      exclusive
                      fullWidth
                      size='small'
                      onChange={handleNegativeImpact}
                      aria-label="text alignment"
                    >
                      <ToggleButton value={-1} id="negative_impact" aria-label="right aligned" sx={{ '&.Mui-selected': { backgroundColor: 'background.paper', color: 'text.primary' }, padding: 0.25, fontSize: "14px", borderTopLeftRadius: '100px', borderBottomLeftRadius: '100px' }}>
                        ?
                      </ToggleButton>
                      <ToggleButton value={0} id="negative_impact" aria-label="left aligned" sx={{ '&.Mui-selected': { backgroundColor: '#FFD3CC', color: '#FF4E33' }, '&:hover': { backgroundColor: '#FFD3CC', color: '#FF4E33' }, padding: 0.25, fontSize: "14px" }}>
                        {/* <FormatAlignLeftIcon /> */}
                        0
                      </ToggleButton>
                      <ToggleButton value={1} id="negative_impact" aria-label="left aligned" sx={{ '&.Mui-selected': { backgroundColor: '#FFA799', color: '#FFF' }, '&:hover': { backgroundColor: '#FFA799', color: '#FFF' }, padding: 0.25, fontSize: "14px" }}>
                        {/* <FormatAlignLeftIcon /> */}
                        -
                      </ToggleButton>
                      <ToggleButton value={2} id="negative_impact" aria-label="centered" sx={{ '&.Mui-selected': { backgroundColor: '#FF7A66', color: '#FFF' }, '&:hover': { backgroundColor: '#FF7A66', color: '#FFF' }, padding: 0.25, fontSize: "14px" }}>
                        {/* <FormatAlignCenterIcon /> */}
                        - -
                      </ToggleButton>
                      <ToggleButton value={3} id="negative_impact" aria-label="right aligned" sx={{ '&.Mui-selected': { backgroundColor: '#FF4E33', color: '#FFF' }, '&:hover': { backgroundColor: '#FF4E33', color: '#FFF' }, padding: 0.25, fontSize: "14px", borderTopRightRadius: '100px', borderBottomRightRadius: '100px' }}>
                        {/* <FormatAlignRightIcon /> */}
                        - - -
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  {/* <Grid
                    item
                    xs={12}
                  >
                    <FormGroup>
                      <FormControlLabel control={<Checkbox checked={formik.values.noGo} onChange={handleNoGo} />} label={(
                        <>
                          {t('no_go')}
                          <div style={{ display: 'inline-block' }}>
                            <InfoPop
                              infoElementName={t('no_go')}
                              shortText='What is a No-Go?'
                              position='right'
                              longText='A No-Go is a show-stopper. When the impact of an Unknown is potentially critical enough to prevent the Project from being viable or sustainable, it is qualified as a “No-Go”. Qualifying an Unknown as a “No-go” will require to  reduce it as early as possible. Once a No-Go is confirmed, it means that the Project should be interrupted and that any further consumption of resources will most likely translate into a waste.e created.  To avoid this waste you should careful plan the sequence of your Plan for reducing Unknowns.'
                            />
                          </div>
                        </>)} />
                    </FormGroup>
                  </Grid> */}
                  <Grid
                    item
                    xs={12}
                    md={12}
                  >
                    <InputField
                      error={Boolean(formik.touched.no_go && formik.errors.no_go)}
                      fullWidth
                      helperText={formik.touched.no_go && formik.errors.no_go}
                      label={t('no_go')}
                      name="no_go"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      required
                      value={formik.values.no_go}
                      select
                    >
                      <MenuItem value={1}>{t('no_go')}</MenuItem>
                      <MenuItem value={2}>{t('critical')}</MenuItem>
                      <MenuItem value={3}>{t('important')}</MenuItem>
                      <MenuItem value={4}>{t('minor')}</MenuItem>
                      <MenuItem value={0}>?</MenuItem>
                    </InputField>
                  </Grid>
                  <Divider />
                  <Grid item xs={12}>
                    <InputField
                      error={Boolean(formik.touched.move && formik.errors.move)}
                      fullWidth
                      helperText={formik.touched.move && formik.errors.move}
                      label={t('Tactical Move to reduce this Unknown')}
                      name="move"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="move"
                      value={(unknown.unknownId !== null && unknown.unknownId !== undefined && unknown.move !== null) ? unknown.move : formik.values.move}
                      multiline
                      disabled={(unknown.unknownId !== null && unknown.unknownId !== undefined) && (unknown.move !== null)}
                    // sx={{'& .MuiFilledInput-root': {borderColor: 'red'}}}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      error={Boolean(formik.touched.outcome && formik.errors.outcome)}
                      fullWidth
                      helperText={formik.touched.outcome && formik.errors.outcome}
                      label={t('Result of Move to reduce Unknown (what has been learned)')}
                      name="outcome"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="outcome"
                      value={formik.values.outcome}
                      multiline
                      rows={1}
                    // sx={{'& .MuiFilledInput-root': {borderColor: 'red'}}}
                    />
                  </Grid>
                  {formik.errors.submit && (
                    <Grid
                      item
                      xs={12}
                    >
                      <FormHelperText error>
                        {formik.errors.submit}
                      </FormHelperText>
                    </Grid>
                  )}
                </>) : <></>
              }
              <Grid><Divider /></Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  color="error"
                  size="small"
                  onClick={onClose}
                  variant="outlined"
                  sx={{ mx: 1 }}
                >
                  {t('cancel')}
                </Button>
                <Button
                  color="error"
                  size="small"
                  disabled={(unknownIsLoading || !unknown.isEditabled || (formik.values.description.length < 3))}
                  onClick={() => { handleSubmitAndQuit(); }}
                  variant="contained"
                  sx={{ mx: 1 }}
                >
                  {(unknown.unknownId !== null && unknown.unknownId !== undefined) ? t('update') : t('create')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {showList && (
            <Grid item xs={4} sx={{ backgroundColor: 'rgba(236, 76, 71, 0.25)', overflowY: "scroll" }} padding={0} spacing={2} height={height}>
              <Grid container spacing={2} padding={4} sx={{ paddingLeft: 2 }}>
                <Grid item xs={12} sx={{ paddingLeft: 0 }}>
                  <Typography variant="h5" style={{ color: "rgb(236, 76, 71)" }}>
                    All unknowns <Chip color='default' label={total} size='small' variant='filled' />
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ paddingLeft: 0 }}>
                  <Button variant='contained' size='small' fullWidth onClick={() => dispatch(openUnknownDialog({ element: {id: null}, isEditabled: true, isDuplicated: false }))} style={{ backgroundColor: 'rgb(236, 76, 71)' }}>
                    New unknown
                  </Button>
                </Grid>
                <Grid item xs={12} sx={{ paddingLeft: 0 }}>
                  <InputField
                    fullWidth
                    placeholder='Search for an unknown'
                    name="search"
                    onChange={(e) => setSearchedString(e.target.value)}
                    type="text"
                    value={searchedString}
                  />
                </Grid>
                <Grid item xs={12}>
                  <List>
                    {items.map((u) => (
                      <ListItem disablePadding sx={{ backgroundColor: "background.default", marginBottom: 1, borderRadius: 1, padding: 0, fontSize: "10px" }}>
                        <ButtonGroup
                          variant="contained"
                          ref={anchorRef}
                          aria-label="Button group with a nested menu"
                          fullWidth
                          sx={{ padding: 0, fontSize: "10px", backgroundColor: 'white' }}
                          color='inherit'
                        >
                          <Button onClick={() => dispatch(openUnknownDialog({ element: u, isEditabled: true, isDuplicated: false }))} sx={{ backgroundColor: 'white', padding: '10px', textAlign: 'left' }}><span style={{ padding: '10px', width: '100%' }}>{u.description}</span></Button>
                          <Button
                            size="small"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            style={{ width: '1%' }}
                            sx={{ backgroundColor: 'white' }}
                          // onClick={handleToggle}
                          >
                            {/* <FontAwesomeIcon icon={faEllipsis as IconProp} /> */}
                            <TableItemMenu
                              onView={() => dispatch(openUnknownDialog({element: u, isEditabled: false, isDuplicated: false}))}
                              onEdit={() => dispatch(openUnknownDialog({element: u, isEditabled: true, isDuplicated: false}))}
                              onDuplicate={() => dispatch(openUnknownDialog({element: u, isEditabled: true, isDuplicated: true}))}
                              onDelete={async () => {
                                await dispatch(unknownDelete(u.id));
                                getItems();
                              }}
                            />
                          </Button>
                        </ButtonGroup>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <Divider />
      {/* <DialogActions>
        <Button
          color="primary"
          size="small"
          onClick={onClose}
          variant="outlined"
        >
          {t('cancel')}
        </Button>
        <Button
          color="success"
          size="small"
          disabled={(unknownIsLoading || !unknown.isEditabled || (formik.values.description.length < 3))}
          onClick={() => { handleSubmitAndQuit(); }}
          variant="contained"
        >
          {(unknown.unknownId !== null && unknown.unknownId !== undefined) ? t('update') : t('create')}
        </Button>
      </DialogActions> */}
      <ConfirmationDialog
        message={t('do_you_want_to_close_without_saving')}
        onCancel={() => { setOpenConfirmationDialog(false) }}
        onConfirm={() => {
          setOpenConfirmationDialog(false);
          onClose();
        }}
        open={openConfirmationDialog}
        title={t('confirmation_title')}
        variant='warning'
      />
    </Dialog>
  );
};

UnknownDialog.defaultProps = {
  open: false
};

UnknownDialog.propTypes = {
  // @ts-ignore
  open: PropTypes.bool,
  onClose: PropTypes.func
};
