import { createSlice } from "@reduxjs/toolkit";
import APIService from "../services/api.service";
import { AppDispatch } from "../store";

const initialState = {
  intellectualPropertyDialogOpen: false,
  intellectualPropertyIsLoading: false,
  intellectualPropertyStatus: null,
  intellectualPropertyNextAction: null,
  intellectualPropertyId: null,
  description: null,
  uniqueFeature: null,
  currentBestMode: null,
  persons: null,
  priorArtDescription: null,
  closestPublication: null,
  isEditabled: true,
  isDuplicated: false,
};

export const intellectualPropertySlice = createSlice({
  name: "intellectualProperty",
  initialState,
  reducers: {
    openIntellectualPropertyDialog: (state, action) => {
      state.intellectualPropertyDialogOpen = true;
      if (action.payload!== undefined && action.payload!== null && Object.is(action.payload, {})) {
        // Element
        if (action.payload.isDuplicated === true) {
          state.intellectualPropertyId = null;
          state.description = `${action.payload.element.description} - copy`;
        } else {
          state.intellectualPropertyId = action.payload.element?.id;
          state.description = action.payload.element?.description;
        }
        state.uniqueFeature = action.payload.element?.unique_feature;
        state.currentBestMode = action.payload.element?.current_best_mode;
        state.persons = action.payload.element?.persons;
        state.priorArtDescription = action.payload.element?.prior_art_description;
        state.closestPublication = action.payload.element?.closest_publication;
        // Other
        state.isEditabled = action.payload.isEditabled;
        state.isDuplicated = action.payload.isDuplicated;
      }
    },
    closeIntellectualPropertyDialog: (state) => {
      state.intellectualPropertyDialogOpen = false;
    },
    loadingIntellectualProperty: (state) => {
      state.intellectualPropertyIsLoading = true;
    },
    resetIntellectualProperty: (state, action) => {
      state.intellectualPropertyIsLoading = false;
      state.intellectualPropertyStatus = 'success';
      state.intellectualPropertyNextAction = action.payload;
    },
    resetIntellectualPropertyStatus: (state) => {
      state.intellectualPropertyStatus = null;
    }
  },
});

export const {
  openIntellectualPropertyDialog,
  closeIntellectualPropertyDialog,
  loadingIntellectualProperty,
  resetIntellectualProperty,
  resetIntellectualPropertyStatus
} = intellectualPropertySlice.actions;

export default intellectualPropertySlice.reducer;

export const intellectualPropertyPost = (nextAction, data: any, id: any = null) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loadingIntellectualProperty());
    if (id !== null && id !== undefined) {
      await APIService.put(`/intellectual-properties/${id}`, data).then((response) => {
        dispatch(resetIntellectualProperty(nextAction));
      });
    } else {
      await APIService.post("/intellectual-properties", data).then((response) => {
        dispatch(resetIntellectualProperty(nextAction));
      });
    }
  } catch (e) {
    // dispatch(setCustomerError());
  }
};
