import * as React from "react";
import { useState } from "react";
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  Card,
} from "@material-ui/core";
import PropTypes from 'prop-types';
import { WizardSimpleCostingDetails } from "./move-wizard-simple-costing-details";
import { useTranslation } from 'react-i18next';

interface WizardSimpleCostingProps {
  data: any;
}


const steps = ["costing"];

export const WizardSimpleCosting: React.FC<WizardSimpleCostingProps> = (props) => {
  const { t } = useTranslation();
  const { data } = props
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [values, setValues] = useState(data);

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleOnNextStep = (newValues: Record<string, any>): void => {
    console.log(newValues)
    setValues((prevValues) => ({ ...prevValues, ...newValues }));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleOnConfirm = async () => {
    console.log("need to create new project", values);
    // onSubmit(values);
    // const status = await projectCreate(values);
    // status === "success" ? (window.location.href = "/") : console.log(status);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <React.Fragment>
        <Box sx={{ pt: 2 }}>
          <Card>
            <WizardSimpleCostingDetails />
          </Card>
        </Box>
      </React.Fragment>
    </Box>
  );
};

WizardSimpleCosting.propTypes = {
  data: PropTypes.object
};
