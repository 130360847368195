import { createSlice } from "@reduxjs/toolkit";
import APIService from "../services/api.service";
import { AppDispatch } from "../store";
import axios from "axios";

const initialState = {
  review4: null,
  moves: null,
  intellectualProperties: null,
  timeline: null,
  history: {
    object: null,
    field: null,
    data: [],
  },
  review4IsInitialized: false
};

export const review4Slice = createSlice({
  name: "review4",
  initialState,
  reducers: {
    getReview4: (state, action) => {
      // state.moves = action.payload.moves;
      // state.intellectualProperties = action.payload.ip;
      state.timeline = action.payload.timeline;
      state.review4IsInitialized = true;
    },
    putReview4: (state, action) => {
      state.review4 = action.payload.review_4;
      state.review4.confidence_indice = Math.min(action.payload.review_4.moves_confidence_indice, action.payload.review_4.ips_confidence_indice, action.payload.review_4.timeline_confidence_indice, action.payload.review_4.budget_confidence_indice);
      // state.moves = action.payload.moves;
      // state.intellectualProperties = action.payload.ip;
    },
    putReview4Details: (state, action) => {
      const cus = action.payload;
      const newData = {...state.review4};
      Object.keys(cus).forEach((k) => {
        newData[k] = cus[k];
      });
      state.review4 = newData;
      state.review4.confidence_indice = Math.min(newData.moves_confidence_indice, newData.ips_confidence_indice, newData.timeline_confidence_indice, newData.budget_confidence_indice);
    },
  },
});

export const {
  getReview4,
  putReview4,
  putReview4Details
} = review4Slice.actions;

export default review4Slice.reducer;

export const review4GetAll = () => async (dispatch: AppDispatch) => {
    try {
      // let moves_q = APIService.get("/moves")
      // let ip_q = APIService.get("/intellectual-properties")
      let review_4_q = APIService.get("/review-4")

      axios.all([review_4_q]).then(axios.spread((...response) => {
        dispatch(putReview4({
          // 'moves': response[0].data.items ? response[0].data.items : [],
          // 'ip': response[1].data.items ? response[1].data.items : [],
          'review_4': response[0].data.review
        }))
      })).catch(e => {
      })

    } catch (e) {
      // dispatch(setCustomerError());
    }
  };

export const review4PutElement =
  (data: object) => async (dispatch: AppDispatch) => {
    try {
      // dispatch(setCustomersLoading());
      await APIService.put('/review-2/companies', data).then(
        (response) => {
          dispatch(putReview4(response.data.data.result));
        }
      );
    } catch (e) {
      // dispatch(setCustomerError());
    }
  };

export const review4PutDetails =
  (data: object) => async (dispatch: AppDispatch) => {
    try {
      // dispatch(setCustomersLoading());
      await APIService.put(`/review-4`, data).then(
        (response) => {
          dispatch(putReview4Details(data));
        }
      );
    } catch (e) {
      // dispatch(setCustomerError());
    }
  };

export const review4UpdateOrder =
  (data: object) => async (dispatch: AppDispatch) => {
    try {
      // dispatch(setCustomersLoading());
      await APIService.put("/factors/order", data).then(
        (response) => {
          // dispatch(getReview4());
        }
      );
    } catch (e) {
      // dispatch(setCustomerError());
    }
  };
