import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
  Skeleton,
  Divider,
  FormGroup, FormControlLabel,
  Switch,
  OutlinedInput,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton,
  Checkbox,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
} from '@material-ui/core';
import LoadingButton from '@material-ui/lab/LoadingButton';
import 'react-loading-skeleton/dist/skeleton.css'
import { InputField } from '../../input-field';
import { DateField } from '../../date-field';
import { decisions, moveConcernings } from '../../../static-data';
import { projectGetStakeholders } from '../../../api/project';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../store';
import { stakeholderPost, resetStakeholderStatus, updateField } from '../../../slices/stakeholderSlice';

interface WizardInfluencingProps {
  activeId: string;
}

export const WizardInfluencing: FC<WizardInfluencingProps> = (props) => {
  const { t } = useTranslation();
  const { activeId } = props;
  const [loadingRequest, setLoadingRequest] = useState(false);
  const { stakeholdersInfluencings } = useSelector((state: RootState) => state.stakeholder);
  const { stakeholders } = useSelector((state: RootState) => state.review3);
  const [localeInfluencings, setLocaleInfluencings] = useState([]);
  const dispatch = useDispatch();

  const getLocaleInfluencings = () => {
    let newData = [];
    stakeholders.forEach(s => {
      console.log("getLocaleInfluencings", s.id, activeId)
      console.log(stakeholdersInfluencings)
      if (s.id !== activeId) {
        let found = false;
        stakeholdersInfluencings?.forEach(inf => {
          if (s.id === inf.id) {
            console.log(s.id);
            newData.push({
              id: s.id,
              name: s.name,
              influence: inf['influence'],
              influencingBy: inf['influencingBy']
            })
            found = true
          }
        });

        if (found === false) {
          newData.push({
            id: s.id,
            name: s.name,
            influence: false,
            influencingBy: false
          })
        }
      }
    });
    setLocaleInfluencings(newData);
    console.log('new dataaaa', newData);
    dispatch(updateField({ parentField: 'stakeholdersInfluencings', field: null, value: newData }));
  }

  useEffect(() => {
    getLocaleInfluencings();
  }, [stakeholders, activeId]);

  const handleUpdateInfluence = (event: React.ChangeEvent<HTMLInputElement>, stakeholder_id: any) => {
    console.log(event.target.checked, stakeholder_id);
    console.log(localeInfluencings);
    let newData = [];
    localeInfluencings.forEach(s => {
      if (s.id === stakeholder_id) {
        newData.push({
          id: s.id,
          name: s.name,
          influence: event.target.checked,
          influencingBy: s['influencingBy']
        })
      } else {
        newData.push({
          id: s.id,
          name: s.name,
          influence: s['influence'],
          influencingBy: s['influencingBy']
        })
      }
    });

    // formik.setFieldValue('influencings', newData)
    setLocaleInfluencings(newData);
    dispatch(updateField({ parentField: 'stakeholdersInfluencings', field: null, value: newData }));
  }

  const handleUpdateInfluencingBy = (event: React.ChangeEvent<HTMLInputElement>, stakeholder_id: any) => {
    console.log(event.target.checked, stakeholder_id);
    console.log(localeInfluencings);
    let newData = [];
    localeInfluencings.forEach(s => {
      if (s.id === stakeholder_id) {
        newData.push({
          id: s.id,
          name: s.name,
          influence: s['influence'],
          influencingBy: event.target.checked
        })
      } else {
        newData.push({
          id: s.id,
          name: s.name,
          influence: s['influence'],
          influencingBy: s['influencingBy']
        })
      }
    });

    // formik.setFieldValue('influencings', newData)
    setLocaleInfluencings(newData);
    dispatch(updateField({ parentField: 'stakeholdersInfluencings', field: null, value: newData }));
  }

  return (
    <Card
      // variant="outlined"
      sx={{ backgroundColor: 'background.default' }}
      {...props}
    >
      <CardContent>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            xs={12}
          >
            <div>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  {/* <Typography
                      variant='subtitle1'
                    >
                      {t('influencing_by')}
                    </Typography> */}

                  {/* Stakeholders */}
                  <Grid container>
                    <TableContainer sx={{ borderRadius: '8px', boxShadow: 7, }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('this_stakeholder')}</TableCell>
                            <TableCell>{t('influencing_who')}</TableCell>
                            <TableCell>{t('influencing_by')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {localeInfluencings?.map((s) => (
                            <TableRow
                              hover
                              key={s.id}
                            >
                              <TableCell>
                                <b>{s.name}</b>
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  checked={s.influence}
                                  onChange={(e) => handleUpdateInfluence(e, s.id)}
                                  sx={{ py: 0 }}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  checked={s.influencingBy}
                                  onChange={(e) => handleUpdateInfluencingBy(e, s.id)}
                                  sx={{ py: 0 }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                </Grid>

                {/* {formik.errors.submit && (
                    <Grid
                      item
                      xs={12}
                    >
                      <FormHelperText error>
                        {formik.errors.submit}
                      </FormHelperText>
                    </Grid>
                  )} */}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

WizardInfluencing.propTypes = {
  activeId: PropTypes.string,
};