import axios from '../services/api.service';

export const stakeholderGetAll = async (orderBy, sort, searchedString, page, limit) => {
  try {
    const { data: response } = await axios.get(`/stakeholders?orderBy=${orderBy}&sort=${sort}&filter[name]=${searchedString}&page=${page}&limit=${limit}`);
    return {items: response.items, err: null, total: response.total};
  } catch (error) {
    return {res: null, err: error};
  }
};

export const stakeholderPutOne = async (id: any, data: object) => {
  try {
    await axios.put(`/stakeholders/${id}`, data);
    return {message: 'success', err: null};
  } catch (error) {
    return {message: null, err: error};
  }
};