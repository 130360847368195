import { createSlice } from "@reduxjs/toolkit";
import APIService from "../services/api.service";
import { AppDispatch } from "../store";

const initialState = {
  factorDialogOpen: false,
  factorId: null,
  description: null,
  comment: null,
  opportunityKiller: 0,
  positiveImpact: 1,
  negativeImpact: 1,
  concernings: [],
  probability: 2,
  preMove: null,
  contMove: null,
  order: null,
  factorIsLoading: false,
  factorStatus: null,
  factorNextAction: null,
  isEditabled: true,
  isDuplicated: false,
};

export const factorSlice = createSlice({
  name: "factor",
  initialState,
  reducers: {
    openFactorDialog: (state, action) => {
      console.log('FACTOR SLICE', action.payload);
      state.factorDialogOpen = true;

      // init
      state.factorId = null;
      state.description = null;
      state.comment = null;
      state.opportunityKiller = 0;
      state.positiveImpact = 1;
      state.negativeImpact = 1;
      state.concernings = [];
      state.probability = 2;
      state.preMove = null;
      state.contMove = null;
      // Other
      state.isEditabled = true;
      state.isDuplicated = false;

      // if (action.payload!== undefined && action.payload!== null && Object.is(action.payload, {})) {
      if (action.payload.element.id) {
        // Element
        if (action.payload.isDuplicated === true) {
          state.factorId = null;
          state.description = `${action.payload.element.description} - copy`;
        } else {
          state.factorId = action.payload.element.id;
          state.description = action.payload.element.description;
        }
        state.comment = action.payload.element.comment;
        state.opportunityKiller = action.payload.element.opportunity_killer;
        state.positiveImpact = action.payload.element.positive_impact;
        state.negativeImpact = action.payload.element.negative_impact;
        state.concernings = action.payload.element.concernings;
        state.probability = action.payload.element.probability;
        state.preMove = action.payload.element.pre_move;
        state.contMove = action.payload.element.cont_move;
        // Other
        state.isEditabled = action.payload.isEditabled;
        state.isDuplicated = action.payload.isDuplicated;
      }
    },
    closeFactorDialog: (state) => {
      state.factorDialogOpen = false;
    },
    loadingFactor: (state) => {
      state.factorIsLoading = true;
    },
    resetFactor: (state, action) => {
      state.factorId = null;
      state.description = null;
      state.comment = null;
      state.opportunityKiller = 0;
      state.positiveImpact = 1;
      state.negativeImpact = 1;
      state.concernings = [];
      state.probability = 2;
      state.preMove = null;
      state.contMove = null;
      state.factorIsLoading = false;
      state.factorStatus = 'success';
      state.factorNextAction = action.payload;
    },
    resetFactorStatus: (state) => {
      state.factorStatus = null;
    }
  },
});

export const {
  openFactorDialog,
  closeFactorDialog,
  loadingFactor,
  resetFactor,
  resetFactorStatus
} = factorSlice.actions;

export default factorSlice.reducer;

export const factorPost = (nextAction, data: any, id: any = null) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loadingFactor());
    if (id !== null && id !== undefined) {
      await APIService.put(`/factors/${id}`, data).then((response) => {
        dispatch(resetFactor(nextAction));
      });
    } else {
      await APIService.post("/factors", data).then((response) => {
        dispatch(resetFactor(nextAction));
      });
    }
  } catch (e) {
    console.log(e);
    // dispatch(setCustomerError());
  }
};

export const factorDelete = (id: string) => async (dispatch: AppDispatch) => {
  try {
    await APIService.delete(`/factors/${id}`).then((response) => {
      console.log(response);
    });
  } catch (e) {
    console.log(e);
  }
};
