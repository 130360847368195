import axios from '../services/api.service';

export const moveGetAll = async (orderBy, sort, searchedString, planType, page, limit) => {
  try {
    const { data: response } = await axios.get(`/moves?orderBy=${orderBy}&sort=${sort}&filter[name]=${searchedString}&filter[plan_type]=${planType}&page=${page}&limit=${limit}`);
    return {items: response.items, err: null, total: response.total};
  } catch (error) {
    return {res: null, err: error};
  }
};

export const movePutOne = async (id: any, data: object) => {
  try {
    await axios.put(`/moves/${id}`, data);
    return {message: 'success', err: null};
  } catch (error) {
    return {message: null, err: error};
  }
};

export const moveDelete = async (id: string) => {
  try {
    await axios.delete(`/moves/${id}`);
    return {message: 'success', err: null};
  } catch (error) {
    return {message: null, err: error};
  }
};