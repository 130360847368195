import { createSlice } from "@reduxjs/toolkit";
import APIService from "../services/api.service";
import { AppDispatch } from "../store";

const initialState = {
  aspirations: [],
  resistances: []
};

export const listSlice = createSlice({
  name: "list",
  initialState,
  reducers: {
    setLists: (state, action) => {
      state.aspirations = action.payload.aspirations ? action.payload.aspirations : [];
      state.resistances = action.payload.resistances ? action.payload.resistances : [];
    },
  },
});

export const {
  setLists
} = listSlice.actions;

export default listSlice.reducer;

export const getLists = () => async (dispatch: AppDispatch) => {
  try {
    await APIService.get("/lists").then((response) => {
      dispatch(setLists({aspirations: response.data.aspirations, resistances: response.data.resistances}));
    });
  } catch (e) {
    console.log(e);
    // dispatch(setCustomerError());
  }
};
