import axios from '../services/api.service';

export const unknownGetAll = async (orderBy, sort, searchedString, page, limit) => {
  try {
    const { data: response } = await axios.get(`/unknowns?orderBy=${orderBy}&sort=${sort}&filter[description]=${searchedString}&page=${page}&limit=${limit}`);
    return {items: response.items, err: null, total: response.total};
  } catch (error) {
    return {res: null, err: error};
  }
};

export const unknownPutOne = async (id: any, data: object) => {
  try {
    await axios.put(`/unknowns/${id}`, data);
    return {message: 'success', err: null};
  } catch (error) {
    return {message: null, err: error};
  }
};

export const unknownDelete = async (id: string) => {
  try {
    await axios.delete(`/unknowns/${id}`);
    return {message: 'success', err: null};
  } catch (error) {
    return {message: null, err: error};
  }
};