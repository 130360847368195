import { FC, useEffect, useRef } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Divider,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Chip,
  ButtonGroup
} from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { InputField } from '../../input-field';
import { ConfirmationDialog }  from '../../confirmation-dialog';
import { review3GetAll} from '../../../slices/review3Slice';
import { stakeholderPost, resetStakeholderStatus, updateField, stakeholderDelete } from '../../../slices/stakeholderSlice';
import { RootState } from '../../../store';
import { Wizard } from './stakeholder-wizard';
import { useTranslation } from 'react-i18next';
import { InfoPop } from '../../info-pop';
import { openStakeholderDialog } from 'src/slices/stakeholderSlice';
import { ChevronLeft as ChevronLeftIcon } from '../../../icons/chevron-left';
import { ChevronRight as ChevronRightIcon } from '../../../icons/chevron-right';
import { stakeholderGetAll } from 'src/api/stakeholder';
import { TableItemMenu } from 'src/components/table-item-menu';

interface StakeholderDialogProps {
  open: boolean;
  onClose: () => void;
}

export const StakeholderDialog: FC<StakeholderDialogProps> = (props) => {
  const { t } = useTranslation();
  const { open, onClose, ...other } = props;
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const stakeholder = useSelector((state: RootState) => state.stakeholder);
  // const {stakeholders} = useSelector((state: RootState) => state.review3);

  const [showList, setShowList] = useState(true);

  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchedString, setSearchedString] = useState('');

  const [height, setHeight] = useState(0)
  const mainGridRef = useRef(null)
  const [refVisible, setRefVisible] = useState(false)

  const anchorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!refVisible) {
      return
    }
    setHeight(mainGridRef.current.clientHeight)
  }, [refVisible, showDetails, mainGridRef?.current?.clientHeight])

  useEffect(() => {
    getItems();
  }, []);

  useEffect(() => {
    getItems();
  }, [searchedString]);

  const getItems = async () => {
    const res = await stakeholderGetAll('name', 'asc', searchedString, 1, 1000);
    if (res.items) {
      setItems(res.items);
      setTotal(res.total);
    } else {
      setItems([]);
      setTotal(0);
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    console.log('store status', stakeholder.stakeholderIsLoading);
    if (stakeholder.stakeholderStatus === 'success') {
      dispatch(review3GetAll());
      toast.success(`Stakeholder ${(stakeholder.stakeholderId !== null && stakeholder.stakeholderId !== undefined) ? 'updated' : 'created'}`);
      dispatch(resetStakeholderStatus());
      if (stakeholder.stakeholderNextAction === 'close') {
        onClose();
      }
    }
  }, [stakeholder.stakeholderStatus]);

  const handleSubmit = () => {
    console.log('Submit and quit');
    let values = {
      profile: stakeholder.stakeholderProfile,
      levers: stakeholder.stakeholderLevers,
      influencings: stakeholder.stakeholdersInfluencings,
    };
    console.log('new stakeholder', values);
    dispatch(stakeholderPost('close', values, stakeholder.stakeholderId));
  };

  return (
    <Dialog
      maxWidth="xl"
      fullWidth
      onClose={() => {stakeholder.stakeholderProfile.name !== '' ? setOpenConfirmationDialog(true) : onClose();}}
      open={open}
      // TransitionProps={{
      //   onExited: () => formik.resetForm()
      // }}
      PaperProps={{
        style: { borderRadius: 25 }
      }}
      {...other}
    >
      {/* <DialogTitle>
        {(stakeholder.stakeholderId !== null && stakeholder.stakeholderId !== undefined) ? `${t('update_stakeholder')} : ${stakeholder.stakeholderProfile.name}` : t('create_stakeholder')}
        <div style={{ display: 'inline-block'}}>
          <InfoPop
            infoElementName={t('stakeholders')}
            shortText='What is a Stakeholder?'
            position='right'
            longText='Stakeholders are the people or entities to whom you are accountable. Those who have the power to influence the success of the Project. <br />
            To gain their support and/or avoid antagonizing them, you need to know their Aspirations, their Resistance as well as the Constraints they impose on you.'
          />
        </div>
      </DialogTitle> */}
      <DialogContent sx={{ padding: 0, backgroundColor: "background.default" }}>
        <Grid
          container
          spacing={2}
          // my={2}
        >
          <Grid item xs={showList ? 8 : 12} sx={{ p: 0, backgroundColor: "background.default" }}>
            <Grid container spacing={2} padding={4} sx={{ overflowY: "scroll" }} maxHeight={'80vh'} ref={el => {mainGridRef.current = el; setRefVisible(!!el);}}>
              <Grid item xs={12}>
                <Typography variant="h5" color={'#8F16C8'}>
                {(stakeholder.stakeholderId !== null && stakeholder.stakeholderId !== undefined) ? `${t('update_stakeholder')} : ${stakeholder.stakeholderProfile.name}` : t('create_stakeholder')}
                  <div style={{ display: 'inline-block'}}>
                    <InfoPop
                      infoElementName={t('unknowns')}
                      shortText='What is a Unknown?'
                      position='right'
                      longText='Unknowns exist when information or knowledge are missing. Once the missing information has been obtained, the Unknown no longer exists. Since Unknowns are only testifying of your ignorance, they have no probability of occurrence like it is the case for Risks.<br />
                      Unknowns can relate to Customers, technology, market, regulations, Stakeholders, organizational issues,Resources, etc.<br />
                      Once Unknowns have been identified, you can figure out the effort (time, money, people, etc.) needed to obtain the missing information/knowledge, which will eventually reduce the Unknowns. You should make sure to reduce Unknowns in the optimal sequence that consumes the least amount of resources (money, time, etc.). <br />
                      To optimize the sequence you should establis an as complete as possible inventory of all identifiable Unknowns. <br />
                      Any Unknown that has not been identified reduces your ability to realistically assess the effort required to reduce Unknowns and/or deliver the Definition of Success.'
                    />
                  </div>
                  <div style={{ display: 'inline-block', textAlign: 'right', float: 'right' }}>
                  <IconButton onClick={() => setShowList(!showList)}>
                    {showList ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                  </IconButton>
                  </div>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <InputField
                  fullWidth
                  label={t('name')}
                  name="name"
                  // onBlur={formik.handleBlur}
                  onChange={(e) => dispatch(updateField({parentField: 'stakeholderProfile', field: 'name', value: e.target.value}))}
                  required
                  type="name"
                  value={stakeholder.stakeholderProfile.name}
                  multiline
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  fullWidth
                  label={t('Comments about this Stakeholder ')}
                  name="comment"
                  // onBlur={formik.handleBlur}
                  onChange={(e) => dispatch(updateField({parentField: 'stakeholderProfile', field: 'comment', value: e.target.value}))}
                  type="comment"
                  value={stakeholder.stakeholderProfile.comment}
                  multiline
                  rows={2}
                />
              </Grid>
              {/* <Grid item xs={12} textAlign="center">
                <Button
                  onClick={() => setShowDetails(!showDetails)}
                  type="button"
                  sx={{ color: '#8F16C8'}}
                >
                  {showDetails === true ? t('hide_details') : t('show_details')}
                </Button>
              </Grid> */}
              {showDetails === true ? (
                <>
                  <Grid item xs={12}>
                    <Wizard activeId={stakeholder.stakeholderId} />
                  </Grid>
                </>
              ) : (<></>)}
              <Grid><Divider /></Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  // color="#8F16C8"
                  size="small"
                  onClick={onClose}
                  variant="outlined"
                  sx={{ mx: 1, color: '#8F16C8'}}
                >
                  Cancel
                </Button>
                <Button
                  // color="primary"
                  size="small"
                  disabled={(stakeholder.stakeholderIsLoading || !stakeholder.isEditabled || (stakeholder.stakeholderProfile.name.length < 3))}
                  onClick={() => { handleSubmit(); }}
                  variant="contained"
                  sx={{ mx: 1, backgroundColor: '#8F16C8' }}
                >
                  {(stakeholder.stakeholderId !== null && stakeholder.stakeholderId !== undefined) ? t('update') : t('create')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          { showList && (
              <Grid item xs={4} sx={{ backgroundColor: "rgba(144, 22, 200, 0.25)", overflowY: "scroll" }} padding={0} spacing={2} height={height}>
                <Grid container spacing={2} padding={4} sx={{paddingLeft: 2}}>
                  <Grid item xs={12} sx={{ paddingLeft: 0 }}>
                    <Typography variant="h5" style={{ color: '#8F16C8' }}>
                      All stakeholders <Chip color='default' label={total} size='small' variant='filled' />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ paddingLeft: 0 }}>
                    <Button variant='contained' size='small' fullWidth onClick={() => dispatch(openStakeholderDialog({ element: {id: null}, isEditabled: true, isDuplicated: false }))} style={{ backgroundColor: '#8F16C8' }}>
                      New stakeholder
                    </Button>
                  </Grid>
                  <Grid item xs={12} sx={{ paddingLeft: 0 }}>
                    <InputField
                      fullWidth
                      placeholder='Search for a stakeholder'
                      name="search"
                      onChange={(e) => setSearchedString(e.target.value)}
                      type="text"
                      value={searchedString}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <List>
                      {items?.map((u) => (
                        <ListItem disablePadding sx={{ backgroundColor: "background.default", marginBottom: 1, borderRadius: 1, padding: 0, fontSize: "10px" }}>
                          <ButtonGroup
                            variant="contained"
                            ref={anchorRef}
                            aria-label="Button group with a nested menu"
                            fullWidth
                            sx={{ padding: 0, fontSize: "10px", backgroundColor: 'white' }}
                            color='inherit'
                          >
                            <Button onClick={() => dispatch(openStakeholderDialog({ element: u, isEditabled: true, isDuplicated: false }))} sx={{ backgroundColor: 'white', padding: '10px', textAlign: 'left' }}><span style={{ padding: '10px', width: '100%' }}>{u.name}</span></Button>
                            <Button
                              size="small"
                              aria-controls={open ? 'split-button-menu' : undefined}
                              aria-expanded={open ? 'true' : undefined}
                              aria-label="select merge strategy"
                              aria-haspopup="menu"
                              style={{ width: '1%' }}
                              sx={{ backgroundColor: 'white' }}
                            // onClick={handleToggle}
                            >
                              {/* <FontAwesomeIcon icon={faEllipsis as IconProp} /> */}
                              <TableItemMenu
                                onView={() => dispatch(openStakeholderDialog({element: u, isEditabled: false, isDuplicated: false}))}
                                onEdit={() => dispatch(openStakeholderDialog({element: u, isEditabled: true, isDuplicated: false}))}
                                onDuplicate={() => dispatch(openStakeholderDialog({element: u, isEditabled: true, isDuplicated: true}))}
                                onDelete={async () => {
                                  await dispatch(stakeholderDelete(u.id));
                                  getItems();
                                }}
                              />
                            </Button>
                          </ButtonGroup>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </Grid>
            )}
        </Grid>
      </DialogContent>
      <Divider />
      {/* <DialogActions>
        <Button
          color="primary"
          size="small"
          onClick={onClose}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          color="success"
          size="small"
          disabled={(stakeholder.stakeholderIsLoading || !stakeholder.isEditabled || (stakeholder.stakeholderProfile.name.length < 3))}
          onClick={() => { handleSubmit(); }}
          variant="contained"
        >
          {(stakeholder.stakeholderId !== null && stakeholder.stakeholderId !== undefined) ? t('update') : t('create')}
        </Button>
      </DialogActions> */}
      <ConfirmationDialog
        message={t('do_you_want_to_close_without_saving')}
        onCancel={() => {setOpenConfirmationDialog(false)}}
        onConfirm={() => {
          setOpenConfirmationDialog(false);
          onClose();
        }}
        open={openConfirmationDialog}
        title={t('confirmation_title')}
        variant='warning'
      />
    </Dialog>
  );
};

StakeholderDialog.defaultProps = {
  open: false
};

StakeholderDialog.propTypes = {
  // @ts-ignore
  open: PropTypes.bool,
  onClose: PropTypes.func
};
