import axios from "axios";

// const API_URL = "http://localhost:1236";
// const API_URL = "http://35.180.156.147:5000";
// const API_URL = 'https://demo-ws.ipoptools.com';
const API_URL = 'https://staging-ws.ipoptools.com';
// MC
// const API_URL = "http://13.38.86.135";

// const token = localStorage.getItem("token");

axios.defaults.withCredentials = true;

const signin = (username: string, password: string) => {
    return axios
        .post(`${API_URL}/users/signin`, {
            username,
            password
        })
}

const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json'
    },
    // withCredentials: true
})

const apiLogged = axios.create({
    baseURL: API_URL,
    // withCredentials: true
})

const authService = {
    signin,
    api,
    apiLogged
};

export default authService;