import { useState } from "react";
import type { FC } from "react";
import { Menu, MenuItem, Divider, Button } from "@material-ui/core";

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faEllipsis,
  faEye,
  faPen,
  faCopy,
  faTrash,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface TableItemMenuProps {
  onView: () => void;
  onEdit: () => void;
  onDuplicate: () => void;
  onDelete: () => void;
  disableView?: boolean;
  disableEdit?: boolean;
  disableDuplicate?: boolean
  disableDelete?: boolean;
}

export const TableItemMenu: FC<TableItemMenuProps> = (props) => {
  const { onView, onEdit, onDuplicate, onDelete, disableView, disableEdit, disableDuplicate, disableDelete } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        color="primary"
        size="small"
        sx={{ ':hover': { backgroundColor: 'transparent' }}}
      >
        <FontAwesomeIcon icon={faEllipsis as IconProp} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        elevation={10}
        sx={{
          '.MuiMenu-paper': {
            border: 'none',
            backgroundColor: 'background.default'
          },
          '.MuiMenu-list': {
            color: 'primary.main',
            '.MuiMenuItem-root:hover': {
              backgroundColor: 'primary.main',
              color: 'primary.contrastText'
            }
          },
        }}
      >
        {!disableView && (
          <MenuItem onClick={() => { onView(); handleClose(); }}>
            <FontAwesomeIcon icon={faEye as IconProp} style={{ marginRight: 10 }} />
            View
          </MenuItem>
        )}
        {!disableEdit && (
          <MenuItem onClick={() => { onEdit(); handleClose(); }}>
            <FontAwesomeIcon icon={faPen as IconProp} style={{ marginRight: 10 }} />
            Edit
          </MenuItem>
        )}
        {!disableDuplicate && (
          <MenuItem onClick={() => { onDuplicate(); handleClose(); }}>
            <FontAwesomeIcon icon={faCopy as IconProp} style={{ marginRight: 10 }} />
            Duplicate
          </MenuItem>
        )}
        {!disableDelete && (
          <>
            <Divider />
            <MenuItem onClick={() => { onDelete(); handleClose(); }}>
              <FontAwesomeIcon icon={faTrash as IconProp} style={{ marginRight: 10 }} />
              Delete
            </MenuItem>
          </>
        )}
      </Menu>
    </>
  );
};
