import axios from '../services/api.service';

export const projectGetAll = async (payload: object) => {
  try {
    const { data: response } = await axios.get('/projects', payload);
    console.log("PROJECTS", response)
    return response.projects;
  } catch (error) {
    console.error(error)
    return 'error';
  }
};

export const projectGetOne = async () => {
  try {
    const { data: response } = await axios.get('/projects/active');
    return response.project;
  } catch (error) {
    console.error(error)
    return 'error';
  }
};

export const projectPutOne = async (data: object) => {
  try {
    await axios.put('/projects', data);
    return 'success';
  } catch (error) {
    console.error(error)
    return 'error';
  }
};

export const projectCreate = async (data: object) => {
  try {
    await axios.post('/projects', data);
    return 'success';
  } catch (error) {
    console.error(error)
    return 'error';
  }
};

export const projectUpdateActive = async (data: object) => {
  try {
    await axios.post('/projects/update-active-project', data);
    return 'success';
  } catch (error) {
    console.error(error)
    return 'error';
  }
};

export const projectGetMembers = async () => {
  try {
    const { data: response } = await axios.get('/projects/members');
    return response.items;
  } catch (error) {
    console.error(error)
    return 'error';
  }
};

export const projectGetStakeholders = async () => {
  try {
    const { data: response } = await axios.get('/projects/stakeholders');
    return response.data.result;
  } catch (error) {
    console.error(error)
    return 'error';
  }
};

export const projectKeyPlayerGetAll = async () => {
  try {
    const { data: response } = await axios.get('/projects/key-players');
    return {items: response.items, err: null};
  } catch (error) {
    return {res: null, err: error};
  }
};

export const projectSkillGetAll = async () => {
  try {
    const { data: response } = await axios.get('/projects/skills');
    return {items: response.items, err: null};
  } catch (error) {
    return {res: null, err: error};
  }
};