import { FC, useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  Popover,
  FormControl,
  Select,
  MenuItem,
  Divider,
  List,
  ListItem, 
  ListSubheader,
  ListItemText,
  Chip,
  ButtonGroup,
  GridSize
} from "@material-ui/core";
import { InputField } from './input-field';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../store';
import LineTo from "react-lineto";
import { SteppedLineTo } from "react-lineto";
import { useTranslation } from 'react-i18next';

const sleep = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

let items = [
  {id: 0, description: 'Item 1', type: 0},
  {id: 1, description: 'Item 2', type: 1},
  {id: 2, description: 'Item 3', type: 1},
]

let pnds = {
  1: 'pain',
  2: 'need',
  3: 'desire'
}

let concerning_columns_1 = [
  {value: 'project', addable: true},
  {value: 'review_1', addable: false},
  {value: 'review_2', addable: false},
  {value: 'review_3', addable: false},
  {value: 'review_4', addable: false}
];

let concerning_columns_2 = [
  {parent_c1: 'review_1', value: 'clients', addable: false},
  {parent_c1: 'review_1', value: 'companies', addable: false},
  {parent_c1: 'review_2', value: 'definition_of_success', addable: true},
  {parent_c1: 'review_2', value: 'definition_of_failure', addable: true},
  {parent_c1: 'review_2', value: 'activity_chain', addable: true},
  {parent_c1: 'review_2', value: 'economic_model', addable: true},
  {parent_c1: 'review_3', value: 'factors', addable: true},
  {parent_c1: 'review_3', value: 'stakeholders', addable: true},
  {parent_c1: 'review_4', value: 'moves', addable: true},
];

let concerning_columns_4 = [
  {parent_c1: 'review_1', parent_c2: 'clients', value: 'P / N / D', addable: true},
  {parent_c1: 'review_1', parent_c2: 'clients', value: 'Benchmarking', addable: true},
  {parent_c1: 'review_1', parent_c2: 'clients', value: 'Experience', addable: true},
  {parent_c1: 'review_1', parent_c2: 'companies', value: 'Benefits', addable: true},
  {parent_c1: 'review_1', parent_c2: 'companies', value: 'Alignement', addable: true},
  {parent_c1: 'review_1', parent_c2: 'companies', value: 'Benchmarking', addable: true},
];

interface PopoverConcerningProps {
  existingConcernings: any[];
  onUpdateConcernings: (newConcernings: any[]) => void;
}

export const PopoverConcerning: FC<PopoverConcerningProps> = (props) => {
  const { t } = useTranslation();
  const { existingConcernings, onUpdateConcernings } = props
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [concernings, setConcernings] = useState([]);
  const [activeC1, setActiveC1] = useState('');
  const [activeC2, setActiveC2] = useState('');
  const [activeC3, setActiveC3] = useState(null);
  // const [activeC4, setActiveC4] = useState('');

  const [widthPopover, setWidthPopover] = useState(900);
  const [widthC1, setWidthC1] = useState<GridSize>(12);
  const [widthC2, setWidthC2] = useState<GridSize | null>(null);
  const [widthC3, setWidthC3] = useState<GridSize | null>(null);
  const [widthC4, setWidthC4] = useState<GridSize | null>(null);

  const [showLines, setShowLines] = useState(false);

  useEffect(() => {
    async function show() {
      await sleep(600);
      if (anchorEl !== null) {
        setShowLines(true)
      } else {
        setShowLines(false);
      }
    };

    show();
  }, [anchorEl]);


  useEffect(() => {
    if (existingConcernings) {
      setConcernings(existingConcernings);
    }
  }, [existingConcernings])

  // Get clients and customers from R1
  const { customers, companies } = useSelector((state: RootState) => state.review1);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    // setShowLines(true);
  };

  const handleClose = () => {
    onUpdateConcernings(concernings);
    setActiveC1('');
    setActiveC2('');
    setActiveC3(null);
    setWidthC4(null);
    setWidthC3(null);
    setWidthC2(null);
    setWidthC1(12);
    // setWidthPopover(150);
    setAnchorEl(null);
    setShowLines(false);
  };

  const handleConcernings = (id, value) => {
    console.log(value)
    let isFound = concernings.some(item => {
      if (item.id === id) {
        return true;
      }
    
      return false;
    });

    if (!isFound) {
      setConcernings([...concernings, {value: value, id: id}])
    }
    console.log(concernings);
  }

  const updateWidth = (col) => {
    if (col === 1) {
      if (widthC1 === 12) {
        // setWidthPopover(300);
        setWidthC1(6);
        setWidthC2(6);
      }
    }

    if (col === 2) {
      if (widthC2 === 6) {
        // setWidthPopover(450);
        setWidthC1(4);
        setWidthC2(4);
        setWidthC3(4);
      }
    }

    if (col === 3) {
      if (widthC3 === 4) {
        // setWidthPopover(600);
        setWidthC1(3);
        setWidthC2(3);
        setWidthC3(3);
        setWidthC4(3);
      }
    }
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <span
        aria-describedby={id}
        onClick={handleClick}
      >
        {concernings.length > 0 ? (
        concernings.map(el => (
            <span style={{ borderRadius: 15, paddingLeft: 4, paddingRight: 4, paddingTop: 2, paddingBottom: 2, backgroundColor: 'white', border: 'solid 1px', borderColor: '#5e6af6', color: '#5e6af6', marginRight: 4, fontSize: 14 }}>
              {t(el.value)}
            </span>
          ))
        ) : (<span style={{fontSize: 14}}>Click to edit elements</span>)}
      </span>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ '& .MuiPaper-root': {backgroundColor: 'background.default'} }}
      >
        <Grid p={1} container width={widthPopover} spacing={1}>
          <Grid item xs={12}>
            {concernings.length > 0 ? (
              concernings.map(el => (
                <span style={{ borderRadius: 15, padding: 4, backgroundColor: 'white', border: 'solid 1px', borderColor: '#5e6af6', color: '#5e6af6', marginRight: 4, overflowX: 'scroll' }}>
                  <span>{t(el.value)}</span>&nbsp;&nbsp;
                  <span style={{ cursor: 'pointer' }} onClick={() => setConcernings(concernings.filter(item => item.id !== el.id))}>x</span>
                </span>
              ))
            ) : (<span><i>No elements</i></span>)}
          </Grid>
        </Grid>
        <Grid p={1}><Divider /></Grid>

        {/* V1 */}
        {/* <Grid p={1} container width={widthPopover} spacing={1}>
          <Grid item xs={3} >
            <div style={{ marginBottom: 4 }}><span><b>Main heading</b></span></div>
            { concerning_columns_1.map((el, idx) => (
              <Typography
                className={`C1-${idx}`}
                variant="body1"
                onClick={() => {
                  if (el.addable) {
                    handleConcernings(el.value, el.value)
                  }
                  if (el.addable === false) {
                    setActiveC1(el.value);
                    setActiveC2('');
                    setActiveC3(null);
                    updateWidth(1);
                  }
                }}
                sx={{
                  backgroundColor: activeC1 === el.value ? 'primary.main' : 'background.paper',
                  borderRadius: 15,
                  padding: 0.5,
                  color: activeC1 === el.value ? 'primary.contrastText' : 'background.contrastText',
                  cursor: 'pointer',
                  marginBottom: 0.5,
                  "&:hover": {
                    color: '#000',
                    backgroundColor: '#EEE'
                  },
                }}
              >
                {el.addable && '+ '}{el.value}
              </Typography>
            ))}
          </Grid>

          <Grid item xs={3} >
            <div style={{ marginBottom: 4 }}><span><b>Sub-heading 1</b></span></div>
            { concerning_columns_2.map(el => (
              <Typography
                variant="body1"
                onClick={() => {
                  if (el.addable) {
                    handleConcernings(el.value, el.value)
                  }
                  if (el.addable === false) {
                    setActiveC2(el.value);
                    setActiveC3(null);
                    updateWidth(2);
                  }
                }}
                sx={{
                  backgroundColor: activeC2 === el.value ? 'primary.main' : 'background.paper',
                  borderRadius: 15,
                  padding: 0.5,
                  color: activeC2 === el.value ? 'primary.contrastText' : 'background.contrastText',
                  cursor: 'pointer',
                  marginBottom: 0.5,
                  "&:hover": {
                    color: '#000',
                    backgroundColor: '#EEE'
                  },
                }}
              >
                {el.addable && '+ '}{el.value}
              </Typography>
            ))}
          </Grid>

          { widthC3 && (
          <Grid item xs={widthC3} >
            <div style={{ marginBottom: 4 }}><span><b>Sub-heading 2</b></span></div>
            { customers.data.map(el => (
              ((activeC1 === 'review_1' && (activeC2 === 'clients' || (activeC2 === ''))) || activeC1 === '') ? (
              <Typography
                variant="body1"
                onClick={() => {
                  setActiveC2('clients');
                  setActiveC3({id: el.id, value: el.name});
                  updateWidth(3);
                }}
                sx={{
                  backgroundColor: activeC3?.id === el.id ? 'primary.main' : 'background.paper',
                  borderRadius: 15,
                  padding: 0.5,
                  color: activeC3?.id === el.id ? 'primary.contrastText' : 'background.contrastText',
                  cursor: 'pointer',
                  marginBottom: 0.5,
                  "&:hover": {
                    color: '#000',
                    backgroundColor: '#EEE'
                  },
                }}
              >
                {el.name}
              </Typography>
              ) : (<></>))
            )}

            { companies.data.map(el => (
              ((activeC1 === 'review_1' && (activeC2 === 'companies' || (activeC2 === ''))) || activeC1 === '') ? (
              <Typography
                variant="body1"
                onClick={() => {
                  setActiveC2('companies');
                  setActiveC3({id: el.id, value: el.name});
                  updateWidth(3);
                }}
                sx={{
                  backgroundColor: activeC3?.id === el.id ? 'primary.main' : 'background.paper',
                  borderRadius: 15,
                  padding: 0.5,
                  color: activeC3?.id === el.id ? 'primary.contrastText' : 'background.contrastText',
                  cursor: 'pointer',
                  marginBottom: 0.5,
                  "&:hover": {
                    color: '#000',
                    backgroundColor: '#EEE'
                  },
                }}
              >
                {el.name}
              </Typography>
              ) : (<></>))
            )}
          </Grid>
          )}

          { widthC4 && (
          <Grid item xs={widthC4} >
            <div style={{ marginBottom: 4 }}><span><b>Sub-heading 3</b></span></div>
            { concerning_columns_4.map(el => (
              (activeC2 === el.parent_c2 && activeC3 !== null) ? (
              <Typography
                variant="body1"
                onClick={() => handleConcernings(`${activeC3.id}__${el.value}`, `${activeC3.value} - ${el.value}`)}
                sx={{
                  backgroundColor: 'background.paper',
                  borderRadius: 15,
                  padding: 0.5,
                  color: 'background.contrastText',
                  cursor: 'pointer',
                  marginBottom: 0.5,
                  "&:hover": {
                    color: '#000',
                    backgroundColor: '#EEE'
                  },
                }}
              >
                {el.addable && '+ '}{el.value}
              </Typography>
              ) : (<></>))
            )}
          </Grid>
          )}

        </Grid> */}

        {/* V2 */}
        { showLines && (
          <>
        { concerning_columns_1.map((el, idx) => (
          <Grid p={0.5} container width={widthPopover} spacing={1}>
            <Grid item xs={2} >
              <Typography
                className={`C1-${idx}`}
                variant="body1"
                onClick={() => {
                  if (el.addable) {
                    handleConcernings(el.value, el.value)
                  }
                  if (el.addable === false) {
                    setActiveC1(el.value);
                    setActiveC2('');
                    setActiveC3(null);
                    updateWidth(1);
                  }
                }}
                sx={{
                  backgroundColor: el.addable ? 'primary.main' : 'background.paper',
                  borderRadius: 15,
                  padding: 0.5,
                  color: el.addable ? 'primary.contrastText' : 'background.contrastText',
                  cursor: 'pointer',
                  marginBottom: 0.5,
                  fontSize: 12,
                  "&:hover": {
                    color: '#000',
                    backgroundColor: '#EEE'
                  },
                }}
              >
                {el.addable && '+ '}{t(el.value)}
              </Typography>
            </Grid>

            { !el.addable && (
              <Grid item xs={9} >
                { concerning_columns_2.map((el2, idx2) => (
                  <Grid px={1} container width={widthPopover} spacing={1}>
                    <Grid item xs={2} >
                    { el2.parent_c1 === el.value && (
                      <>
                      <Typography
                        className={`C2-${idx2}`}
                        variant="body1"
                        onClick={() => {
                          if (el2.addable) {
                            handleConcernings(el2.value, el2.value)
                          }
                          if (el2.addable === false) {
                            setActiveC2(el2.value);
                            setActiveC3(null);
                            updateWidth(2);
                          }
                        }}
                        sx={{
                          backgroundColor: el2.addable ? 'primary.main' : 'background.paper',
                          borderRadius: 15,
                          padding: 0.5,
                          color: el2.addable ? 'primary.contrastText' : 'background.contrastText',
                          cursor: 'pointer',
                          marginBottom: 0.5,
                          fontSize: 12,
                          "&:hover": {
                            color: '#000',
                            backgroundColor: '#EEE'
                          },
                        }}
                      >
                        {el2.addable && '+ '}{t(el2.value)}
                      </Typography>
                      <SteppedLineTo delay={0} from={`C1-${idx}`} to={`C2-${idx2}`} orientation="h" zIndex={10000} fromAnchor="center right" toAnchor="center left" borderColor="#5e6af6" />
                      </>
                    )}
                    {/* { concerning_columns_1.map((el, idx2) => (
                      <SteppedLineTo delay={0} from={`C1-${idx2}`} to={`C2-${idx}`} orientation="h" zIndex={10000} fromAnchor="center right" toAnchor="center left" />
                    ))} */}
                    
                  </Grid>
                  { (!el2.addable && el.value !== 'review_2' && el.value !== 'review_3' && el.value !== 'review_4') && (
                    <Grid item xs={10} >
                      { el2.value === 'clients' && (
                        <>
                          <Grid px={1} container width={widthPopover} spacing={1}>
                            <Grid item xs={3} >
                              <FormControl fullWidth >
                                <Select
                                  className={`C3-clients`}
                                  value={"0"}
                                  // onChange={(e) => onChangeField(i, 'level', '', e.target.value)}
                                  onChange={(e) => {
                                    e.target.value !== "0" && handleConcernings(`${e.target.value}`, customers.data.filter(x => x.id === e.target.value.replace('client-', ''))[0]?.name)
                                  }}
                                  variant="standard"
                                  disableUnderline
                                  sx={{
                                    backgroundColor: concernings.filter(x => x.id.includes('client-')).length > 0 ? 'primary.main' : 'background.paper',
                                    color: concernings.filter(x => x.id.includes('client-')).length > 0 ? 'primary.contrastText' : 'background.contrastText',
                                    borderRadius: 15,
                                    px: 0.5,
                                    marginBottom: 0.5,
                                    fontSize: 12
                                  }}
                                >
                                  <MenuItem sx={{fontSize: 12}} value={"0"}>Choose clients ({concernings.filter(x => x.id.includes('client-')).length})</MenuItem>
                                  <Divider />
                                  {customers.data.map((el3, idx3) => (
                                    <MenuItem sx={{
                                        color: concernings.filter(x => x.id.replace('client-', '') === el3.id).length === 1 ? '#FFF' : '',
                                        fontWeight: concernings.filter(x => x.id.replace('client-', '') === el3.id).length === 1 ? 'bold' : '',
                                        backgroundColor: concernings.filter(x => x.id.replace('client-', '') === el3.id).length === 1 ? 'primary.main' : '',
                                        fontSize: 12
                                      }} value={`client-${el3.id}`}>{el3.name}</MenuItem>
                                  ))}
                                </Select>
                                <SteppedLineTo delay={0} from={`C2-${idx2}`} to={`C3-clients`} orientation="h" zIndex={10000} fromAnchor="center right" toAnchor="center left" borderColor="#5e6af6" />
                              </FormControl>
                            </Grid>
                          </Grid>
                          {/* { customers.data.map((el3, idx3) => (
                            <Grid px={1} container width={widthPopover} spacing={1}>
                              <Grid item xs={3} >
                                <Typography
                                  className={`C3-clients-${idx3}`}
                                  variant="body1"
                                  sx={{
                                    backgroundColor: el3.addable ? 'primary.main' : 'background.paper',
                                    borderRadius: 15,
                                    padding: 0.5,
                                    color: el3.addable ? 'primary.contrastText' : 'background.contrastText',
                                    marginBottom: 0.5,
                                    fontSize: 12,
                                  }}
                                >
                                  {el3.name}
                                </Typography>
                                <SteppedLineTo delay={0} from={`C2-${idx2}`} to={`C3-clients-${idx3}`} orientation="h" zIndex={10000} fromAnchor="center right" toAnchor="center left" borderColor="#5e6af6" />
                            </Grid>
                            <Grid item xs={4} >
                              <Grid
                              className={`C4-clients-${idx3}-details`}
                              container
                              spacing={0.25}
                              sx={{
                                backgroundColor: 'primary.main',
                                borderRadius: 15,
                                paddingBottom: 0.25,
                                paddingRight: 0.25,
                                marginBottom: 0.5,
                                marginTop: 0,
                                fontSize: 12,
                              }}
                              >
                              { concerning_columns_4.map((el4, idx4) => (
                                (el2.value === el4.parent_c2) ? (
                                  <Grid item xs={4}>
                                    <Typography
                                      className={`C4-clients-${idx3}-page-${idx4}`}
                                      variant="body1"
                                      onClick={() => handleConcernings(`${el3.id}__${el4.value}`, `${el3.name} - ${el4.value}`)}
                                      sx={{
                                        backgroundColor: 'background.paper',
                                        borderRadius: 15,
                                        padding: 0.25,
                                        color: 'background.contrastText',
                                        cursor: 'pointer',
                                        fontSize: 12,
                                        "&:hover": {
                                          color: '#000',
                                          backgroundColor: '#EEE'
                                        },
                                      }}
                                    >
                                      {el4.addable && '+ '}{el4.value}
                                    </Typography>
                                    
                                  </Grid>
                                ) : (<></>))
                              )}
                              </Grid>
                              <SteppedLineTo delay={0} from={`C3-clients-${idx3}`} to={`C4-clients-${idx3}-details`} orientation="h" zIndex={10000} fromAnchor="center right" toAnchor="center left" borderColor="#5e6af6" />
                            </Grid>
                          </Grid>
                        ))} */}
                      </>
                    )}
                    { el2.value === 'companies' && (
                        <>
                          <Grid px={1} container width={widthPopover} spacing={1}>
                              <Grid item xs={3} >
                                <FormControl fullWidth >
                                  <Select
                                    className={`C3-companies`}
                                    value={"0"}
                                    // onChange={(e) => onChangeField(i, 'level', '', e.target.value)}
                                    onChange={(e) => {
                                      e.target.value !== "0" && handleConcernings(`${e.target.value}`, companies.data.filter(x => x.id === e.target.value.replace('company-', ''))[0]?.name)
                                    }}
                                    variant="standard"
                                    disableUnderline
                                    sx={{
                                      backgroundColor: concernings.filter(x => x.id.includes('company-')).length > 0 ? 'primary.main' : 'background.paper',
                                      color: concernings.filter(x => x.id.includes('company-')).length > 0 ? 'primary.contrastText' : 'background.contrastText',
                                      borderRadius: 15,
                                      px: 0.5,
                                      marginBottom: 0.5,
                                      fontSize: 12
                                    }}
                                  >
                                    <MenuItem sx={{fontSize: 12}} value={"0"}>Choose companies/investors ({concernings.filter(x => x.id.includes('company-')).length})</MenuItem>
                                    <Divider />
                                    {companies.data.map((el3, idx3) => (
                                      <MenuItem sx={{
                                          color: concernings.filter(x => x.id.replace('company-', '') === el3.id).length === 1 ? '#FFF' : '',
                                          fontWeight: concernings.filter(x => x.id.replace('company-', '') === el3.id).length === 1 ? 'bold' : '',
                                          backgroundColor: concernings.filter(x => x.id.replace('company-', '') === el3.id).length === 1 ? 'primary.main' : '',
                                          fontSize: 12
                                        }} value={`company-${el3.id}`}>{el3.name}</MenuItem>
                                    ))}
                                  </Select>
                                  <SteppedLineTo delay={0} from={`C2-${idx2}`} to={`C3-companies`} orientation="h" zIndex={10000} fromAnchor="center right" toAnchor="center left" borderColor="#5e6af6" />
                                </FormControl>
                              </Grid>
                            </Grid>
                          {/* { companies.data.map((el3, idx3) => (
                            <Grid px={1} container width={widthPopover} spacing={1}>
                              <Grid item xs={3} >
                                <Typography
                                  className={`C3-companies-${idx3}`}
                                  variant="body1"
                                  sx={{
                                    backgroundColor: el3.addable ? 'primary.main' : 'background.paper',
                                    borderRadius: 15,
                                    padding: 0.5,
                                    color: el3.addable ? 'primary.contrastText' : 'background.contrastText',
                                    marginBottom: 0.5,
                                    fontSize: 12
                                  }}
                                >
                                  {el3.name}
                                </Typography>
                                <SteppedLineTo delay={0} from={`C2-${idx2}`} to={`C3-companies-${idx3}`} orientation="h" zIndex={10000} fromAnchor="center right" toAnchor="center left" borderColor="#5e6af6" />

                            </Grid>
                            <Grid item xs={4} >
                              <Grid
                              className={`C4-companies-${idx3}-details`}
                              container
                              spacing={0.25}
                              sx={{
                                backgroundColor: 'primary.main',
                                borderRadius: 15,
                                paddingBottom: 0.25,
                                paddingRight: 0.25,
                                // color: 'primary.contrastText',
                                marginBottom: 0.5,
                                marginTop: 0,
                                fontSize: 12,
                              }}
                              >
                              { concerning_columns_4.map((el4, idx4) => (
                                (el2.value === el4.parent_c2) ? (
                                  <Grid item xs={4}>
                                    <Typography
                                      className={`C4-companies-${idx3}-page-${idx4}`}
                                      variant="body1"
                                      onClick={() => handleConcernings(`${el3.id}__${el4.value}`, `${el3.name} - ${el4.value}`)}
                                      sx={{
                                        backgroundColor: 'background.paper',
                                        borderRadius: 15,
                                        padding: 0.25,
                                        color: 'background.contrastText',
                                        cursor: 'pointer',
                                        fontSize: 12,
                                        "&:hover": {
                                          color: '#000',
                                          backgroundColor: '#EEE'
                                        },
                                      }}
                                    >
                                      {el4.addable && '+ '}{el4.value}
                                    </Typography>
                                  </Grid>
                                ) : (<></>))
                              )}
                              </Grid>
                              <SteppedLineTo delay={0} from={`C3-companies-${idx3}`} to={`C4-companies-${idx3}-details`} orientation="h" zIndex={10000} fromAnchor="center right" toAnchor="center left" borderColor="#5e6af6" />
                            </Grid>
                          </Grid>
                        ))} */}
                      </>
                    )}
                  </Grid>
                )}
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      ))}
      </>
      )}

        <Grid p={1}><Divider /></Grid>

        <Grid p={1} container width={widthPopover} spacing={1}>
          <Grid item xs={12}>
            <Button
              size="small"
              variant="contained"
              fullWidth
              onClick={() => handleClose()}
            >Update concernings</Button>
          </Grid>
        </Grid>
        
        
      </Popover>
    </>
  );
};
