import { useState } from 'react';
import type { FC, ElementType } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, IconButton, List, Typography, ListItem, ListItemText } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { Scrollbar } from './scrollbar';
import { ChevronRight as ChevronRightIcon } from '../icons/chevron-right';
import { useAuth } from '../hooks/use-auth';
import { review1PutCustomerDetails, review1PutCompanyDetails } from '../slices/review1Slice';
import { RootState } from '../store';
import { ResourceLoading } from "./resource-loading";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faThumbTack } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

interface DashboardSidebarProps {
  onPin: () => void;
  pinned: boolean;
}

interface Item {
  href?: string;
  external?: boolean;
  icon: ElementType;
  items?: Array<{ href: string; title: string; }>
  title: string;
}

export const LogSidebar: FC<DashboardSidebarProps> = (props) => {
  const { t } = useTranslation();
  const { onPin, pinned } = props;
  const { pathname } = useLocation();
  const [openedItem, setOpenedItem] = useState<Item | null>(null);
  const [activeItem, setActiveItem] = useState<Item | null>(null);
  const [activeHref, setActiveHref] = useState('');
  const [hovered, setHovered] = useState(false);
  const { accessRights, activeProject } = useAuth() as any;

  const log = useSelector((state: RootState) => state.log)
  const dispatch = useDispatch();

  const handleOnChooseLog = (elementName: string, parentId: string, field: string, value: string) => {
    let detail = ''
    switch(elementName) {
      case 'customer_pnd':
        dispatch(review1PutCustomerDetails(
          'pnd',
          parentId,
          {[field]: value}
        ));
        break;
      case 'customer_experience':
        dispatch(review1PutCustomerDetails(
          'experience',
          parentId,
          {[field]: value}
        ));
        break;
      case 'company_benefits':
        dispatch(review1PutCompanyDetails(
          'benefits',
          parentId,
          {[field]: value}
        ));
        break;
    }
  }

  return (
    <Drawer
      open
      sx={{ zIndex: 1000 }}
      variant="permanent"
      anchor='right'
      PaperProps={{
        onMouseOver: () => { setHovered(true); },
        onMouseLeave: () => { setHovered(false); },
        sx: {
          backgroundColor: 'background.paper',
          height: 'calc(100% - 64px)',
          overflowX: 'hidden',
          top: 64,
          transition: 'width 250ms ease-in-out',
          width: pinned ? 350 : 0,
          '& .simplebar-content': {
            height: '100%'
          },
          // '&:hover': {
          //   width: 350,
          //   '& span, p': {
          //     display: 'flex'
          //   }
          // }
        }
      }}
    >
      <Scrollbar
        // style={{
        //   display: 'flex',
        //   flex: 1,
        //   overflowX: 'hidden',
        //   overflowY: 'auto'
        // }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            p: 2
          }}
        >
          { !log.isLoading ? (
          <>
            <Typography
              variant='h6'
            >
              { t(log.elementName) } - { t(log.elementField) }
            </Typography>
            <Divider />
            <List>
            { log.logs.map((l) => (
              <ListItem
                alignItems="flex-start"
                secondaryAction={
                  <IconButton edge="end" aria-label="delete" onClick={() => handleOnChooseLog(l.element_name, l.parent_id, l.element_field, l.element_value)}>
                    <FontAwesomeIcon icon={faThumbTack as IconProp} size="sm" />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={
                    <>
                      <b>{l.user} - {l.created_at}</b>
                    </>
                  }
                  secondary={
                    <>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {l.element_value}
                      </Typography>
                      <br />
                    </>
                  }
                />
              </ListItem>
            ))}
            </List>
          </>
          ) : (
            <ResourceLoading />
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Divider />
          <Box sx={{ pt: 1 }}>
            <IconButton onClick={onPin}>
              <ChevronRightIcon />
            </IconButton>
          </Box>
        </Box>
      </Scrollbar>
    </Drawer>
  );
};

LogSidebar.propTypes = {
  onPin: PropTypes.func,
  pinned: PropTypes.bool
};
