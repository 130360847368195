import * as React from "react";
import { useState } from "react";
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  Card,
} from "@material-ui/core";
import PropTypes from 'prop-types';
import { WizardGeneral } from "./move-wizard-general";
import { WizardDetails } from "./move-wizard-details";
import { WizardCosting } from "./move-wizard-costing";
import { WizardDetailedImpact } from "./move-wizard-detailed-impact";
import { projectCreate } from "../../api/project";
import { useTranslation } from 'react-i18next';

interface WizardProps {
  data: any;
}


// const steps = ["essentials", "more_details", "costing", "detailed_impact"];
const steps = ["essentials", "planning", "move_details"];

export const Wizard: React.FC<WizardProps> = (props) => {
  const { t } = useTranslation();
  const { data } = props
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [values, setValues] = useState(data);

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleOnNextStep = (newValues: Record<string, any>): void => {
    console.log(newValues)
    setValues((prevValues) => ({ ...prevValues, ...newValues }));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleOnConfirm = async () => {
    console.log("need to create new project", values);
    // onSubmit(values);
    // const status = await projectCreate(values);
    // status === "success" ? (window.location.href = "/") : console.log(status);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} nonLinear>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          // if (isStepSkipped(index)) {
          //   stepProps.completed = false;
          // }
          stepProps.completed = false;
          return (
            <Step key={label} {...stepProps} onClick={() => setActiveStep(index)} sx={{cursor: 'pointer'}}>
              <StepLabel {...labelProps} sx={{cursor: 'pointer'}}>{t(label)}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            {t('all_steps_completed')}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleBack}>{t('back')}</Button>
            <Button disabled={!data.isEditabled} onClick={handleOnConfirm}>{(data.moveId !== null && data.moveId  !== undefined) ? t('update') : t('create')}</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ pt: 2 }}>
            <Card>
              {activeStep === 0 && (
                <WizardGeneral />
              )}
              {activeStep === 1 && (
                <WizardDetails />
              )}
              {activeStep === 2 && (
                <WizardCosting />
              )}
              {/* {activeStep === 3 && (
                <WizardDetailedImpact />
              )} */}
            </Card>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            {activeStep > 0 && (
              <Button
                color="success"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
               {`< ${t('back')}`}
              </Button>
            )}
            <Box sx={{ flex: "1 1 auto" }} />
            {activeStep < steps.length - 1 && (
              <Button onClick={handleNext} color="success" >
                {`${t('next_step')} >`}
              </Button>
            )}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

Wizard.propTypes = {
  data: PropTypes.object
};
