import axios from "axios";


// const API_URL = 'http://localhost:1236';
// const API_URL = 'http://35.180.156.147:5000';
// const API_URL = 'https://demo-ws.ipoptools.com';
const API_URL = 'https://staging-ws.ipoptools.com';
// MC
// const API_URL = "http://13.38.86.135";

axios.defaults.withCredentials = true;

const instance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json'
    },
    // withCredentials: true
})

instance.interceptors.response.use(
    (res) => {
        return res;
    }, async (err) => {
        const originalConfig = err.config;

        if(err.response && window.location.pathname!== "/login" && !window.location.pathname.includes("/validation/") && !window.location.pathname.includes("/register/")) {
            if((err.response.status === 401 || err.response.status === 422) && !originalConfig._retry) {
                window.location.href = "/login";
            }
        }

        return Promise.reject(err);
    }
)

export default instance;