import axios from '../services/api.service';

export const factorGetAll = async (orderBy, sort, searchedString, page, limit) => {
  try {
    const { data: response } = await axios.get(`/factors?orderBy=${orderBy}&sort=${sort}&filter[description]=${searchedString}&page=${page}&limit=${limit}`);
    return {items: response.items, err: null, total: response.total};
  } catch (error) {
    return {res: null, err: error};
  }
};

export const factorPutOne = async (id: any, data: object) => {
  try {
    await axios.put(`/factors/${id}`, data);
    return {message: 'success', err: null};
  } catch (error) {
    return {message: null, err: error};
  }
};