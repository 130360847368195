import { createSlice } from "@reduxjs/toolkit";
import APIService from "../services/api.service";
import { AppDispatch } from "../store";
import axios from "axios";

const initialState = {
  feasibilityStudies: null,
  ganttCharts: null,
  comments: null
};

export const reportsReducer = createSlice({
  name: "reports",
  initialState,
  reducers: {
    getReports: (state, action) => {
      state.feasibilityStudies = action.payload.fs_q;
      state.comments = action.payload.comments;
    },
    putReports: (state, action) => {
      state.feasibilityStudies = action.payload.fs_q;
      state.comments = action.payload.comments;
    },
    // putReview4Details: (state, action) => {
    //   const cus = action.payload.data;
    //   const newData = {...state.review4};
    //   let key = action.payload.detailType;
    //   if (action.payload.detailType == 'economic-model') {
    //     key = 'economic_model'
    //   }
    //   if (action.payload.detailType == 'activity-chain') {
    //     key = 'activity_chain'
    //   }
    //   Object.keys(cus).forEach((k) => {
    //     newData[key][k] = cus[k];
    //   });
    //   state.review4 = newData;
    // },
  },
});

export const {
  getReports,
  putReports,
  // putReview4Details
} = reportsReducer.actions;

export default reportsReducer.reducer;

export const reportsGetAll = () => async (dispatch: AppDispatch) => {
    try {
      // let fs_q = APIService.get("/feasibility-study")
      // let gc_q = APIService.get("/gantt-chart")
      let comments_q = APIService.get("/comments")

      axios.all([comments_q]).then(axios.spread((...response) => {
        console.log('COMMENTS', response[0].data)
        dispatch(putReports({
          // 'fs': response[0].data.data.result,
          'comments': response[0].data.items,
        }))
      })).catch(e => {
      })

    } catch (e) {
      // dispatch(setCustomerError());
    }
  };
