import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      // Sign in
      'login': 'Log in',
      'logout': 'Log out',
      'signup': 'Sign up',
      'email': 'Email',
      'password': 'Password',
      'forgot_password': 'Forgot password',
      'you_can_use': 'You can use',
      'and_password': 'and password',
      'username': 'Username',
      // Navbar
      'navbar_new_project': 'New project',
      'navbar_app_settings': 'App settings',
      'toast_language_changed': 'Language changed',
      'view_all': 'View all',
      'new_project': 'New project',
      // General
      'unaddressed': 'Unaddressed',
      'quick_and_dirty': 'Quick and dirty',
      'advanced': 'Advanced',
      'reliable': 'Reliable',
      'save': 'Save',
      'invite': 'Invite',
      'members': 'Members',
      'name': 'Name',
      'role': 'Role',
      'actions': 'Actions',
      'edit': 'Edit',
      'remove': 'Remove',
      'description': 'Description',
      'review_max': 'Review max',
      'admin_space': 'Admin space',
      'editor': 'Editor',
      'viewer': 'Viewer',
      'not_allowed': 'Not allowed',
      'cancel': 'Cancel',
      'send_invitation': 'Send invitation',
      'add': 'Add',
      'created_at': 'Created on',
      'modified_at': 'Last modification',
      'update': 'Update',
      'create': 'Create',
      'not_concerned': 'Not concerned',
      'not_important': 'Not important',
      'important': 'Important',
      'very_important': 'Very important',
      'critical': 'Critical',
      'not_provided': 'Not provided',
      'poor': 'Poor',
      'fair': 'Fair',
      'good': 'Good',
      'outstanding': 'Outstanding',
      'report': 'Report',
      'feasibility_study': 'Opportunity case',
      'gantt_chart': 'Gantt chart',
      'tools': 'Tools',
      'go_to': 'Go to',
      'review_1_customers': 'Review 1 : Customers',
      'review_1_customer_fit': 'Review 1 : Customer Fit',
      'review_1_company_fit': 'Review 1 : Decision Maker Fit',
      'do_you_want_to_close_without_saving': 'You are about to exit this window: please choose one of the following options',
      'confirmation_title': 'Confirmation',
      'confirmation': 'Confirmation',
      'alternative_name': 'Alternative name',
      'hide_details': 'Hide details',
      'show_details': 'Show details',
      'is_constraint': 'Constraint',
      'all_plans': 'All plans',
      'presentation': 'Tutorial',
      'tutorial': 'Tutorial',
      'report_a_bug': 'Report a bug',
      // Organization
      'organization': 'Organization',
      'general': 'General',
      'organization_settings': 'Organization settings',
      'settings': 'Settings',
      'organization_name': 'Organization name',
      'company_size': 'Company size',
      'team_name': 'Team name',
      // Project
      'invite_team_member': 'Invite a team member',
      'project_administration': 'Project administration',
      'people': 'People',
      'add_team_member': 'Add team member',
      'add_validator': 'Add validator',
      'add_key_player': 'Add key player',
      // Account
      'account': 'Account',
      'projects': 'Projects',
      'reports': 'Reports',
      'overview': 'Overview',
      'sales': 'Sales',
      'general_settings': 'General settings',
      'notifications': 'Notifications',
      'team': 'Team',
      'billing': 'Billing',
      'project': 'Project',
      'project_settings': 'Project settings',
      'project_team': 'Project team',
      // Review 1
      'customer': 'Customer',
      'review_1': 'Hope ?',
      'customers': 'Customers',
      'company_fit': 'Investors & Companies',
      'update_customer': 'Update customer',
      'create_customer': 'Create customer',
      'customer_deletion': 'Customer deletion',
      'customer_deletion_message': 'Are you sure to delete this customer ?',
      'pnd': 'Pain / Need / Desire',
      'benchmarking': 'Benchmarking',
      'experience': 'Experience',
      'who_is_the_customer': 'Who is the costumer ?',
      'customer_issue_problem': 'What is the customer\'s issue/problem ?',
      'price_per_unit': 'Price per unit',
      'unit': 'Unit',
      'target_market_comments': 'Target market comments',
      'decision_criteria': 'Decision criteria',
      'importance': 'Importance',
      'focus': 'Focus',
      'criteria_name': 'Criteria name',
      'elevator_pitch_label': 'Elevator pitch',
      'cue_statement_label': 'CUE Statement : Continue the statement starting with "I/we recommend this ... because it is the only one that ..."',
      'reason_to_buy_label': 'What is the compelling reason to buy ?',
      'estimated_cost_and_price_label': 'Estimated Cost & Price',
      'cue_comments_label': 'Comments about our ability to deliver this Customer Unique Experience (including availability of technical or other capabilities)',
      'pre_con_cue_label': 'What are the prerequisites or conditions required for this CUE to work ?',
      'cue_resitances_label': 'Outline all possible Resistances that buyers, users or specifiers could express when this CUE is presented to them',
      'selling_price': 'Seling price',
      'estimated_cost': 'Estimated cost',
      'profit_margin': 'Profit margin',
      'benefits': 'Benefits',
      'benefits_for_our_company_label': 'Benefits for our company',
      'brand_essence_compatibility_label': 'Compatibility with Brand essence',
      'concerned_divisions_label': 'Concerned divisions',
      'confidence_indice_label': 'confidence_indice_label',
      'corporate_mission_label': 'Which components of our corporate mission will benefit from seizing this opportunity ?',
      'geographical_scope_label': 'Geographical scope (Territory)',
      'get_results_by_label': 'Get results by',
      'kpi_benefit_label': 'Which of our corporate Key Performance Indicators will be improved by seizing this opportunity ?',
      'launch_date_label': 'Launch date',
      'market_size_label': 'Market size',
      'not_seizing_opportunity_label': 'What will happen if our company does not seize this opportunity ?',
      'strategic_fit_label': 'Strategic fit',
      'target_audience_label': 'Target audience',
      'company': 'Investor/Company',
      'pre_requisites': 'Pre-requisites',
      'client_aspiration': 'Client aspiration',
      'days_ago': 'day(s) ago',
      'why_not_implemented_label': 'Why this Solution has not yet been implemented ?',
      'skills_required_label': 'What skills are required to implement this Solution ?',
      'number_of_customers_label': 'Number of Customers with the same buying profile defined by Customer Decision Criteria',
      'turnover_generated_label': 'Turnover that all these Customers can generate (Market size)',
      'market_penetration_label': 'Your market penetration: number of those Customers that you can reach',
      'market_penetration_strategy_label': 'Your market penetration strategy (how you will reach these Customers) ',
      'update_company': 'Update company or investor',
      'create_company': 'Create company or investor',
      // Review 2
      'review_2': 'Worth it ?',
      'definition_of_success_and_failure': 'Definition of Success & Failure',
      'milestones': 'Milestones',
      'kiss': 'Key Indicators of Success (KISs)',
      'terminators': 'Key Indicators of Failures (Terminators)',
      'activity_chain': 'Activity chain',
      'customer_relationships_strategies': 'Special characteristics of the Customer Relationships strategy',
      'marketing_strategies': 'Special characteristics of the Marketing strategy',
      'distribution_strategies': 'Special characteristics of the Distribution strategy',
      'product_development_strategies': 'Special characteristics of the  Product Development strategy',
      'production_strategies': 'Special characteristics of the Production strategy',
      'service_strategies': 'Special characteristics of the Service strategy',
      'ip_strategies': 'Special characteristics of the I.P. strategy',
      'procurement_strategies': 'Special characteristics of the Procurement strategy',
      'infrastructure_strategies': 'Special characteristics of the Service strategy',
      'partnering_strategies': 'Special characteristics of the Partnering strategy',
      'hr_strategies': 'Special characteristics of the H.R. strategy',
      'training_strategies': 'Special characteristics of the Training strategy',
      'financial_strategies': 'Special characteristics of the Financial strategy',
      'change_of_processes_strategies': 'Special characteristics of the Change of processes strategy',
      'change_management_strategies': 'Special characteristics of the Change management strategy',
      'economic_model': 'Generation of Resources',
      'review_2_economic_model': 'How will project obtain recurrent resources ?',
      'kiss_field': 'Kiss',
      'terminator_field': 'Terminator',
      'mechanism': 'Mechanism',
      'target': 'Target',
      'what': 'What',
      'comment': 'Comment',
      'skills': 'Skills',
      'benefit': 'Benefit',
      'day_per_man': 'Day per man',
      'percentage_available': 'Percentage available',
      // Review 3
      'review_3': 'Influencers ?',
      'probability': 'Probability',
      'opportunity_killer': 'Opport. killer',
      'neg_impact': 'Negative impact',
      'pos_impact': 'Positive impact',
      'pre_move': 'Pre. move',
      'cont_move': 'Cont. move',
      'low': 'Low',
      'medium': 'Medium',
      'high': 'High',
      'no': 'No',
      'yes': 'Yes',
      'preventive_move': 'Preventive move',
      'contigency_move': 'Contengency move',
      'concerning': 'Concerning',
      'update_and_quit': 'Update & quit',
      'add_factor': 'Add factor',
      'create_factor': 'Create factor',
      'update_factor': 'Update factor',
      'add_stakeholder': 'Add stakeholder',
      'update_stakeholder': 'Update stakeholder',
      // Review 4
      'review_4': 'How ?',
      'launch_plan': 'Launch plan',
      'reduce_unknowns_plan': 'Reduce unknowns plan',
      'intellectual_properties': 'Intellectual properties',
      'moves': 'Moves',
      'add_move': 'Add move',
      'add_intellectual_property': 'Add intellectual property',
      'consultant': 'Consultant',
      'it': 'I.T.',
      'timeline': 'Timeline',
      'budget_summary': 'Budget summary',
      // Quick Actions
      // // Unknown
      'create_unknown': 'Create unknown',
      'concerning_id': 'Concerning ID',
      'positive_impact': 'Positive impact',
      'negative_impact': 'Negative impact',
      'no_go': 'No go',
      'add_tactical_move': 'Add tactical move',
      'create_and_quit': 'Create and quit',
      'create_and_add_another_one': 'Create and add another one',
      'collateral_effects': 'Collateral effects',
      'resources': 'Resources',
      'action_plan': 'Action plan',
      'stakeholders': 'Stakeholders',
      'factors': 'Factors',
      'scope': 'Scope',
      'company_decision_criteria': 'Company decision criteria',
      'solution': 'Solution',
      'customer_benchmarking': 'Customer benchmarking',
      'customer_knowledge': 'Customer knowledge',
      'customer_pnds': 'Customer PNDs',
      'add_unknown': 'Add unknown',
      'update_unknown': 'Update unknown',
      // // Move
      'create_move': 'Create move',
      'essentials': 'Essentials',
      'more_details': 'More details',
      'costing': 'Costing',
      'detailed_impact': 'Detailed impact',
      'selected_for_action_plan': 'Selected for action plan',
      'selected_for_launch_plan': 'Selected for launch plan',
      'selected_for_reduce_unknown_plan': 'Selected for reduce unknown plan',
      'decision_for_action_plan': 'Recommendation for Action plan',
      'must_be_done': 'Must be done',
      'should_be_done': 'Should be done',
      'nice_to_do': 'Nice to do',
      'not_worth_it': 'Not worth it',
      'manage_stakeholders': 'Manage stakeholders',
      'fit_company': 'Investor/Company Fit',
      'reduce_resitances': 'Reduce resistances',
      'reduce_unknowns': 'Reduce unknonwns',
      'reduce_constraints': 'Reduce constraints',
      'reduce_risks': 'Reduce risks',
      'overcome_obstacles': 'Overcome obstacles',
      'benefit_of_boosters': 'Benefit of boosters',
      'create_barrier_to_entry': 'Create barrier to entry',
      'deliver_benefits': 'Deliver benefits',
      'convince_customers': 'Convince customers',
      'increase_market_penetration': 'Increase market penetration',
      'obtain_the_resource': 'Obtain the resource',
      'reduce_collateral_effect': 'Reduce collaterial effect',
      'support_the_team': 'Support the team',
      'start_date': 'Start date',
      'end_date': 'End date',
      'user': 'User',
      'back': 'Back',
      'next_step': 'Next step',
      'description_of_the_move': 'Description of the move',
      'kpi': 'Key Indicators of Performance (KPI)',
      'goal_date': 'Goal date',
      'description_of_the_goal': 'Description of the goal',
      'units': 'Units',
      'amount_per_unit': 'Amount / Unit',
      'workingCapital': 'Working capital',
      'equipmentCost': 'Equipment cost',
      'consultants': 'Consultants',
      'subcontractors': 'Subcontractors',
      'travelExpenses': 'Travel expenses',
      'seniorManagement': 'Senior management',
      'intermediateManagement': 'Intermediate management',
      'engineering': 'Engineering',
      'admin': 'Admin',
      'production': 'Production',
      'space': 'Space',
      'total': 'Total',
      'definition_of_success': 'Definition of success',
      'definition_of_failure': 'Definition of failure',
      'client_decision_criteria': 'Client decision criteria',
      'unknowns': 'Unknowns',
      'obstacles': 'Obstacles',
      'risks': 'Risks',
      'boosters': 'Boosters',
      'constraints': 'Constraints',
      'resistances': 'Resistances',
      'aspirations': 'Pain/Need/Desire(s)',
      'all_steps_completed': 'All steps completed !',
      'update_move': 'Update move',
      'planning': 'Planning',
      'move_details': 'Move details',
      // // IP
      'create_intellectual_property': 'Create intellectual property',
      'unique_feature': 'Unique feature',
      'current_best_mode': 'Current best mode',
      'persons': 'Persons',
      'prior_art_description': 'Prior art description',
      'closest_publication': 'Closest publication',
      // // Comment
      'create_comment': 'Create comment',
      'message': 'Message',
      'share_with_team': 'Share with team',
      'comments': 'Comments',
      'link': 'Link',
      'url': 'URL',
      'review_3_stakeholders': 'Review 3 : Stakeholders',
      // // Document
      'create_document': 'Upload documents',
      'title': 'Title',
      // // Stakeholders
      'create_stakeholder': 'Create stakeholder',
      'profile': 'Profile',
      'lever': 'Levers',
      'influencing': 'Influence',
      'level_of_power': 'Level of power',
      'level_of_support': 'Level of support',
      'how_impacted': 'How impacted',
      'influencing_who': 'Can influence',
      'influencing_by': 'Is influenced by',
      'stakeholder_benefits': 'Benefits for this stakeholder derived from the Solution',
      'stakeholder_collateral_damages': 'Collateral Damage(s) that this Stakeholder could experience',
      'aspirations_label': 'Aspiration',
      'resistances_label': 'Resistance',
      'this_stakeholder': 'This Stakeholder',
      // Logs
      'customer_pnd': 'PND',
      'customer_experience': 'Solution / Experience',
      'company_benefits': 'Benefits',
      // Reports
      'feasibility_studies': 'Opportunity cases',
      'gantt_charts': 'Gantt charts',
      'create_new_version': 'Create new version',
      // LABELS
      'concerning_label': 'concerning',
      'reduced_by': 'Reduced by',
      'probability_label': 'Proba-bility',
      'move_description': 'Move description',
      'selected': 'Selected',
      'start': 'Start',
      'end': 'End',
      'create_bug_report': 'Please share to us bugs and improvements',
      // INFO
      'info_short_text_6': 'What are Customers?',
      'info_long_text_6': 'Customers are potential users/adopters of a proposed product/solution. These can be individuals or businesses. A product/solution must have at least one customer type, but will most likely have multiple. Different customer types will have different experiences with the product/solution and will have different expectations and requirements. (i.e., Credit Cards have 3 different types of customers: 1. Credit Card users that use the product to purchase goods and/or services; 2.  Businesses that use Credit Cards to sell their goods and/or services; 3. Banks that sell credit cards to their customers.',
      'info_short_text_7': 'Add all the potential customer types that your solution/product may have',
      'info_short_text_8': 'Add an Unknown',
      'info_short_text_9': 'Add a Stakeholder',
      'info_short_text_10': 'Add a Factor',
      'info_short_text_11': 'Add a Move',
      'info_short_text_12': 'Add Intelectual Property',
      'info_short_text_17': 'Does this solution match your Company/Investor\'s stretegy & culture?',
      'info_long_text_17': 'How does this product/solution fit your company/investors strategy from a business and brand perspective? Does it fit the company/investor’s culture? Will it cannibalize an existing product/solution? How will it impact existing teams and resources? How will it impact existing customers and providers? Etc.',
      'info_short_text_32': 'Add Aternative Solutions or competitors addressing the same PNDs',
      'info_short_text_X': '',
      'info_long_text_X': '',
      // WELCOME MESSAGE
      'wm_the_ipop_tools': 'The IpOp Tools',
      'wm_the_ipop_reviews': 'The IpOp Reviews',
      'wm_quick_actions_buttons': 'Quick Actions Buttons',
      'wm_best_practices': 'Best Practices',
      'dont_show_again': 'Do not Show Again',
      'close': 'Close',
      // PDF
      'empty_fields': 'Empty Fields',
      'show_current_version': 'Show current version',
      'download': 'Download',
      'new_version': 'New Version',
      // OTHER
      'alignement': 'Alignement',
      'strategic_fit': 'Strategic fit',
      'concerned_divisions': 'Concerned divisions',
      'potential_synergies': 'Potential synergies',
      'risk_of_canibalization': 'Risk of canibalization',
      'constrains': 'Constrains',
      'biggest_challenges': 'Biggest challenges',
      'brand_essence_compatibility': 'Brand essence compatibility',
      'geographical_scope': 'Geographical scope',
      'target_audience': 'Target audience',
      'wow': 'Wow effect',
      'potential_evolution': 'Potential evoluation',
      'worth_it': 'Worth it ?',
      'estimated_chance_of_success': 'Estimated chance of success',
      'when_poc': 'When launch proof of concept',
      'demonstration_be_made': 'When demonstration be made',
      'certain': 'Certain',
      'for_sure': 'For sure',
      'minor': 'Minor',
      'version': 'Version',
      'target_reader': 'Target reader',
      'requested_decision': 'Requested decision',
      'current_stage': 'Current stage',
      'next_steps': 'Next steps',
      'validations': 'Validations',
    }
  },
  fr: {
    translation: {
      // Sign in
      'login': 'Se connecter',
      'logout': 'Se déconnecter',
      'signup': 'S\'inscrire',
      'email': 'Email',
      'password': 'Mot de passe',
      'forgot_password': 'Mot de passe oublié',
      'you_can_use': 'Vous pouvez utiliser',
      'and_password': 'et le mot de passe',
      // Navbar
      'navbar_new_project': 'Nouveau projet',
      'navbar_app_settings': 'Paramètres',
      'toast_language_changed': 'La langue a changé',
      // General
      'save': 'Sauvegarder',
      'invite': 'Inviter',
      'members': 'Membres',
      'name': 'Nom',
      'role': 'Rôle',
      'actions': 'Actions',
      'edit': 'Modifier',
      'remove': 'Supprimer',
      'description': 'Description',
      'review_max': 'Revue max',
      'team_name': 'Nom d\'équipe',
      'admin_space': 'Espace Admin',
      'editor': 'Éditeur',
      'viewer': 'Lecture seule',
      'not_allowed': 'Pas autorisé',
      'cancel': 'Annuler',
      'send_invitation': 'Envoyer l\'invitation',
      'add': 'Ajouter',
      'created_at': 'Créé le',
      'modified_at': 'Modifié le',
      'update': 'Mettre à jour',
      'create': 'Créer',
      'not_concerned': 'Pas concerné',
      'not_important': 'Pas important',
      'important': 'Important',
      'very_important': 'Très important',
      'critical': 'Critique',
      'not_provided': 'Non fourni',
      'poor': 'Pauvre',
      'fair': 'Equilibré',
      'good': 'Bon',
      'outstanding': 'Exceptionnel',
      'report': 'Rapport',
      'feasibility_study': 'Document d\'opportunité',
      'gantt_chart': 'Gantt chart',
      'tools': 'Outils',
      'review_1_customers': 'Revue 1 : Clients',
      'review_1_customer_fit': 'Revue 1 : Fit Client',
      'review_1_company_fit': 'Revue 1 : Fit Entreprise',
      'do_you_want_to_close_without_saving': 'Voulez-vous fermer la fenêtre sans sauvegarder ?',
      'confirmation_title': 'Confirmation',
      'tutorial': 'Tutoriel',
      // Organization
      'organization': 'Organisation',
      'general': 'Général',
      'organization_settings': 'Paramètres de l\'organisation',
      'settings': 'Paramètres',
      'organization_name': 'Nom de l\'organisation',
      'company_size': 'Taille de l\'entreprise',
      // Project
      'invite_team_member': 'Inviter un membre d\'équipe',
      'project_administration': 'Administration du projet',
      // Account
      'account': 'Compte',
      'projects': 'Projets',
      'reports': 'Rapports',
      'overview': 'Vue d\'ensemble',
      'sales': 'Ventes',
      'general_settings': 'Paramètres généraux',
      'notifications': 'Notifications',
      'team': 'Équipe',
      'billing': 'Paiement',
      'project': 'Projet',
      'project_settings': 'Paramètres du projet',
      'project_team': 'Équipe du projet',
      // Review 1
      'review_1': 'Revue 1',
      'customers': 'Clients',
      'company_fit': 'Entreprises & investisseurs',
      'update_customer': 'Mettre à jour le client',
      'create_customer': 'Créer un client',
      'customer_deletion': 'Suppression d\'un client',
      'customer_deletion_message': 'Êtes-vous sûr de vouloir supprimer ce client ?',
      'pnd': 'Douleur / Besoin / Désir',
      'benchmarking': 'Benchmarking',
      'experience': 'Expérience',
      'who_is_the_customer': 'Qui est le client ?',
      'customer_issue_problem': 'Quel est le problème du client ?',
      'price_per_unit': 'Prix par unité',
      'target_market_comments': 'Commentaires sur le marché cible',
      'unit': 'Unité',
      'decision_criteria': 'Critère de décision',
      'importance': 'Importance',
      'focus': 'Focus',
      'criteria_name': 'Nom du critère',
      'elevator_pitch_label': 'Elevator pitch',
      'cue_statement_label': 'CUE Statement : Continue the statement starting with "I/we recommend this ... because it is the only one that ..."',
      'reason_to_buy_label': 'What is the compelling reason to buy ?',
      'estimated_cost_and_price_label': 'Estimated Cost & Price',
      'cue_comments_label': 'Comments about our ability to deliver this Customer Unique Experience (including availability of technical or other capabilities)',
      'pre_con_cue_label': 'What are the prerequisites or conditions required for this CUE to work ?',
      'cue_resitances_label': 'Outline all possible Resistances that buyers, users or specifiers could express when this CUE is presented to them',
      'selling_price': 'Prix de vente',
      'estimated_cost': 'Coût estimé',
      'profit_margin': 'Marge',
      'benefits': 'Bénéfices',
      'benefits_for_our_company_label': 'Bénéfices pour notre entreprise',
      'brand_essence_compatibility_label': 'Compatibilité avec l\'essence de la marque',
      'concerned_divisions_label': 'Divisions concernés',
      'confidence_indice_label': 'confidence_indice_label',
      'corporate_mission_label': 'Which components of our corporate mission will benefit from seizing this opportunity ?',
      'geographical_scope_label': 'Portée géographique',
      'get_results_by_label': 'Résultats avant',
      'kpi_benefit_label': 'Which of our corporate Key Performance Indicators will be improved by seizing this opportunity ?',
      'launch_date_label': 'Date de lancement',
      'market_size_label': 'Taille du marché',
      'not_seizing_opportunity_label': 'What will happen if our company does not seize this opportunity ?',
      'strategic_fit_label': 'Ajustement stratégique',
      'target_audience_label': 'Audience ciblée',
      'company': 'Entreprise',
      'pre_requisites': 'Pré-requis',
      'client_aspiration': 'Aspiration du client',
      'days_ago': 'day(s) ago',
      'update_company': 'Mettre à jour une entreprise ou une partie prenante',
      'create_company': 'Créer ue entreprise ou une partie prenante',
      // Review 2
      'review_2': 'Revue 2',
      'definition_of_success_and_failure': 'Définition du succès et de l\'échec',
      'milestones': 'Milestones',
      'kiss': 'Indicateurs clés de succès (KISs)',
      'terminators': 'Indicateurs clés d\'échec (Terminators)',
      'activity_chain': 'Chaîne d\'activité',
      'customer_relationships_strategies': 'Special characteristics of the Customer Relationships strategy',
      'marketing_strategies': 'Special characteristics of the Marketing strategy',
      'distribution_strategies': 'Special characteristics of the Distribution strategy',
      'product_development_strategies': 'Special characteristics of the  Product Development strategy',
      'production_strategies': 'Special characteristics of the Production strategy',
      'service_strategies': 'Special characteristics of the Service strategy',
      'ip_strategies': 'Special characteristics of the I.P. strategy',
      'procurement_strategies': 'Special characteristics of the Procurement strategy',
      'infrastructure_strategies': 'Special characteristics of the Service strategy',
      'partnering_strategies': 'Special characteristics of the Partnering strategy',
      'hr_strategies': 'Special characteristics of the H.R. strategy',
      'training_strategies': 'Special characteristics of the Training strategy',
      'financial_strategies': 'Special characteristics of the Financial strategy',
      'change_of_processes_strategies': 'Special characteristics of the change of processes strategy',
      'change_management_strategies': 'Special characteristics of the change management strategy',
      'economic_model': 'Génération des ressources',
      'review_2_economic_model': 'Comment le projet génerera des revenus récurrents ?',
      'kiss_field': 'Kiss',
      'terminator_field': 'Terminator',
      'mechanism': 'Mécanisme',
      'target': 'Cible',
      'what': 'Quoi',
      'comment': 'Commentaire',
      'skills': 'Compétences',
      'benefit': 'Bénéfice',
      // Review 3
      'review_3': 'Revue 3',
      'probability': 'Probabilité',
      'opportunity_killer': 'Tueur d\'opportunité',
      'neg_impact': 'Impact négatif',
      'pos_impact': 'Impact positif',
      'pre_move': 'Pre. move',
      'cont_move': 'Cont. move',
      'low': 'Basse',
      'medium': 'Moyenne',
      'high': 'Haute',
      'no': 'Non',
      'yes': 'Oui',
      'preventive_move': 'Preventive move',
      'contigency_move': 'Contengency move',
      'update_and_quit': 'Mettre à jour & quitter',
      'add_factor': 'Ajouter un facteur',
      'create_factor': 'Créer un facteur',
      'update_factor': 'Mettre à jour le facteur',
      'add_stakeholder': 'Ajouter une partie prenante',
      'update_stakeholder': 'Mettre à jour la partie prenante',
      // Review 4
      'review_4': 'Revue 4',
      'launch_plan': 'Plan de lancement',
      'reduce_unknowns_plan': 'Plan de réduction des inconnues',
      'intellectual_properties': 'Propriétés intellectuelles',
      'moves': 'Actions tactiques',
      'add_move': 'Ajouter une action tactique',
      'add_intellectual_property': 'Ajouter une propriété intellectuelle',
      'consultant': 'Consultant',
      'it': 'I.T.',
      // Quick Actions
      // // Unknown
      'create_unknown': 'Créer une inconnue',
      'concerning': 'Cela concerne',
      'positive_impact': 'Impact positif',
      'negative_impact': 'Impact négatif',
      'no_go': 'No go',
      'add_tactical_move': 'Ajouter un mouvement tactique',
      'create_and_quit': 'Créer & quitter',
      'create_and_add_another_one': 'Créer & ajouter un nouveau',
      'collateral_effects': 'Effets collatéraux',
      'resources': 'Ressources',
      'action_plan': 'Plan d\'action',
      'stakeholders': 'Parties prenantes',
      'factors': 'Facteurs',
      'scope': 'Scope',
      'company_decision_criteria': 'Critére de décision de l\'entreprise',
      'solution': 'Solution',
      'customer_benchmarking': 'Benchmarking du client',
      'customer_knowledge': 'Connaissance du client',
      'customer_pnds': 'PNDs du client',
      // // Move
      'create_move': 'Créer un move',
      'essentials': 'Général',
      'more_details': 'Plus de détails',
      'costing': 'Coûts',
      'detailed_impact': 'Détails de l\'impact',
      'selected_for_action_plan': 'Sélectionné pour le plan d\'action',
      'selected_for_launch_plan': 'Sélectionné pour le plan de lancement',
      'selected_for_reduce_unknown_plan': 'Sélectionné pour le plan de réduction d\'inconnues',
      'decision_for_action_plan': 'Recommandation pour le plan d\'action',
      'must_be_done': 'Doit être fait',
      'should_be_done': 'Devrait être fait',
      'nice_to_do': 'Bon à faire',
      'not_worth_it': 'N\'en vaut pas la peine',
      'manage_stakeholders': 'Gérer les parties prenantes',
      'fit_company': 'Fit entreprise',
      'reduce_resitances': 'Réduire les résistances',
      'reduce_unknowns': 'Réduire les inconnues',
      'reduce_constraints': 'Réduire les contraintes',
      'reduce_risks': 'Réduire les risques',
      'overcome_obstacles': 'Surmonter les obstacles',
      'benefit_of_boosters': 'Bénéfice du booster',
      'create_barrier_to_entry': 'Crée une barière à l\'entrée',
      'deliver_benefits': 'Délivre un bénéfice',
      'convince_customers': 'Convaincre les clients',
      'increase_market_penetration': 'Augmenter la pénétration du marché',
      'obtain_the_resource': 'Obtenir la ressource',
      'reduce_collateral_effect': 'Réduire les effets collatéraux',
      'support_the_team': 'Supporter l\'équipe',
      'start_date': 'Date de début',
      'end_date': 'Date de fin',
      'user': 'Utilisateur',
      'back': 'Retour',
      'next_step': 'Étape suivante',
      'description_of_the_move': 'Description du move',
      'kpi': 'Indicateurs clé de performance (KPI)',
      'goal_date': 'Date d\'objectif',
      'description_of_the_goal': 'Description de l\'objectif',
      'units': 'Unités',
      'amount_per_unit': 'Coût / unité',
      'workingCapital': 'Working capital',
      'equipmentCost': 'Coût de l\'équipement',
      'consultants': 'Consultants',
      'subcontractors': 'Sous-traitants',
      'travelExpenses': 'Dépenses de voyage',
      'seniorManagement': 'Management senior',
      'intermediateManagement': 'Management intermédiaire',
      'engineering': 'Ingénierie',
      'admin': 'Admin',
      'production': 'Production',
      'space': 'Espace',
      'total': 'Total',
      'definition_of_success': 'Définition du succès',
      'definition_of_failure': "Définition de l'échec",
      'client_decision_criteria': 'Critère de décision du client',
      'unknowns': 'Inconnues',
      'obstacles': 'Obstacles',
      'risks': 'Risques',
      'boosters': 'Boosters',
      'constraints': 'Contraintes',
      'resistances': 'Résistances',
      'aspirations': 'Douleur/Besoin/Désir(s)',
      'all_steps_completed': 'Toutes les étapes sont complétées !',
      'add_unknown': 'Ajouter une inconnue',
      // // IP
      'create_intellectual_property': 'Créer une propriété intellectuelle',
      'unique_feature': 'Fonctionnalité unique',
      'current_best_mode': 'Meilleur mode actuel',
      'persons': 'Personnes',
      'prior_art_description': 'Prior art description',
      'closest_publication': 'Closest publication',
      // // Comment
      'create_comment': 'Créer un commentaire',
      'message': 'Message',
      'share_with_team': 'Partager avec l\'équipe',
      'comments': 'Commentaires',
      'link': 'Lien',
      // // Document
      'create_document': 'Créer un document',
      'title': 'Titre',
      // // Stakeholders
      'create_stakeholder': 'Créer une partie prenante',
      'profile': 'Profil',
      'lever': 'Levier',
      'influencing': 'Influences',
      'level_of_power': 'Niveau de pouvoir',
      'level_of_support': 'Niveau de support',
      'how_impacted': 'Comment impacte-t-il ?',
      'influencing_who': 'Influence qui ?',
      'influencing_by': 'Influencé par ?',
      // Logs
      'customer_pnd': 'PND',
      'customer_experience': 'Expérience',
      'company_benefits': 'Bénéfices',
       // Reports
       'feasibility_studies': 'Dossiers d\'opportunités',
       'gantt_charts': 'Gantt charts',
       'create_new_version': 'Créer une nouvelle version',
       // LABELS
      'concerning_label': 'concerning',
      'reduced_by': 'Réduit par',
      'probability_label': 'prob.',
      'move_description': 'Description'
    }
  }
};

export const initializeI18n = (lng: string): void => {
  i18n
    .use(initReactI18next)
    .init({
      resources,
      lng,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false
      }
    });
};
