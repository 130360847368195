export const access = {
    1: {
        label: 'editor',
        color: 'success'
    },
    2: {
        label: 'viewer',
        color: 'warning'
    },
    3: {
        label: 'editor',
        color: 'error'
    }
}

export const statusMember = {
    1: {
        label: 'active',
        color:'success'
    },
    2: {
        label: 'waiting',
        color: 'warning'
    },
    3: {
        label: 'rejected',
        color: 'warning'
    },
    4: {
        label: 'deleted',
        color: 'danger'
    }
}

export const confidenceIndice = {
    1: {
        color: '#b7bdcc',
        label: 'unaddressed'
    },
    2: {
        color: '#f44336',
        label: 'quick_and_dirty'
    },
    3: {
        color: '#d9822b',
        label: 'medium'
    },
    4: {
        color: '#52bb8b',
        label: 'advanced'
    },
    5: {
        color: '#1b774d',
        label: 'reliable'
    }
}

export const concernings = {
    1: {label: 'team'},
    2: {label: 'collateral_effects'},
    3: {label: 'resources'},
    4: {label: 'action_plan'},
    5: {label: 'stakeholders'},
    6: {label: 'factors'},
    7: {label: 'definition_of_success_and_failure'},
    8: {label: 'economic_model'},
    9: {label: 'activity_chain'},
    10: {label: 'scope'},
    11: {label: 'company_decision_criteria'},
    12: {label: 'solution'},
    13: {label: 'customer_benchmarking'},
    14: {label: 'customer_knowledge'},
    15: {label: 'customer_pnds'},
    16: {label: 'Build Barrier to entry'},
}

export const decisions = [
    {
      value: 1,
      label: 'must_be_done'
    },
    {
      value: 2,
      label: 'should_be_done'
    },
    {
      value: 3,
      label: 'nice_to_do'
    },
    {
      value: 4,
      label: 'not_worth_it'
    },
]

export const decisionsCell = {
    0: {label: 'unknonwn'},
    1: {label: 'must_be_done'},
    2: {label: 'should_be_done'},
    3: {label: 'nice_to_do'},
    4: {label: 'not_worth_it'}
}

export const moveConcernings = {
    1: {label: 'manage_stakeholders'},
    2: {label: 'fit_company'},
    3: {label: 'reduce_resitances'},
    // 4: {label: 'reduce_unknowns'},
    5: {label: 'reduce_constraints'},
    6: {label: 'reduce_risks'},
    7: {label: 'overcome_obstacles'},
    8: {label: 'benefit_of_boosters'},
    9: {label: 'create_barrier_to_entry'},
    10: {label: 'deliver_benefits'},
    11: {label: 'convince_customers'},
    12: {label: 'increase_market_penetration'},
    13: {label: 'obtain_the_resource'},
    14: {label: 'reduce_collateral_effect'},
    15: {label: 'support_the_team'},
}

export const moveDetailedImpacts = {
    1: {label: 'definition_of_success'},
    2: {label: 'client_decision_criteria'},
    3: {label: 'unknowns'},
    4: {label: 'obstacles'},
    5: {label: 'risks'},
    6: {label: 'boosters'},
    7: {label: 'constraints'},
    8: {label: 'resistances'},
    9: {label: 'aspirations'}
}

export const review1PNDFields = [
    "customer_issue_problem",
    "aspirations",
    "resistances",
    "price_per_unit",
    "target_market_comments",
    "pre_requisites"
]

export const validationFields = {
    'review-1': {
        customerspnd: {
            customer_issue_problem: { type: 'string', selected: false },
            aspirations: { type: 'aspirations', selected: false },
            resistances: { type: 'resistances', selected: false },
            price_per_unit: { type: 'price_per_unit', selected: false },
            target_market_comments: { type: 'string', selected: false },
            pre_requisites: { type: 'string', selected: false }
        },
        customersbenchmarking: {
            benchmarking: { type: 'benchmarking', selected: false }
        },
        customersexperience: {
            elevator_pitch: { type: 'string', selected: false },
            cue_statement: { type:'string', selected: false },
            reason_to_buy: { type: 'string', selected: false },
            cue_comments: { type: 'string', selected: false },
            pre_con_cue: { type: 'string', selected: false },
            cue_resistances: { type: 'string', selected: false },
            why_not_implemented: { type: 'string', selected: false },
            skills_required: { type: 'string', selected: false },
            number_of_customers: { type: 'string', selected: false },
            turnover_generated: { type: 'string', selected: false },
            market_penetration: { type: 'string', selected: false },
            market_penetration_strategy: { type: 'string', selected: false },
            wow: { type: 'string', selected: false },
            change_needed: { type: 'string', selected: false },
        },
        companiesbenefits: {
            geographical_scope: { type: 'string', selected: false },
            concerned_divisions: { type: 'string', selected: false },
            market_size: { type: 'string', selected: false },
            target_audience: { type: 'string', selected: false },
            launch_date: { type: 'string', selected: false },
            get_results_by: { type: 'string', selected: false },
            benefits_for_our_company: { type: 'string', selected: false },
            corporate_mission: { type: 'string', selected: false },
            kpi_benefit: { type: 'string', selected: false },
            brand_essence_compatibility: { type: 'string', selected: false },
            not_seizing_opportunity: { type: 'string', selected: false },
        },
        companiesalignement: {
            strategic_fit: { type: 'string', selected: false },
            concerned_divisions: { type: 'string', selected: false },
            potential_synergies: { type: 'string', selected: false },
            risk_of_canibalization: { type: 'string', selected: false },
            constrains: { type: 'string', selected: false },
            biggest_challenges: { type: 'string', selected: false },
            brand_essence_compatibility: { type: 'string', selected: false },
            geographical_scope: { type: 'string', selected: false },
            target_audience: { type: 'string', selected: false },
            wow: { type: 'string', selected: false },
            potential_evolution: { type: 'string', selected: false },
            worth_it: { type: 'string', selected: false },
            estimated_chance_of_success: { type: 'string', selected: false },
            when_poc: { type: 'string', selected: false },
            demonstration_be_made: { type: 'string', selected: false },
        },
        companiesbenchmarking: {
            benchmarking: { type: 'benchmarking', selected: false }
        }
    },
}