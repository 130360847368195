import { createSlice } from "@reduxjs/toolkit";
import AuthService from "../services/auth.service";
import { AppDispatch } from "../store";

const user = localStorage.getItem("it_user");
const _user = user ? JSON.parse(user) : {};
const _token = user ? _user["token"] : "";
const _refresh_token = user ? _user["refresh_token"] : "";
const _isLogged = user ? true : false;
const _projects = user ? _user["projects"] : "";
const ar = localStorage.getItem("it_access_rights");
const _access_rights = ar ? JSON.parse(ar) : {
    "project_admin_space": 3,
    "review_1": 3,
    "review_2": 3,
    "review_3": 3,
    "review_4": 3
};

const initialState = {
    user: _user,
    token: _token,
    refresh_token: _refresh_token,
    message: "",
    isLogged: _isLogged,
    projects: _projects,
    accessRights: _access_rights
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        signIn: (state, action) => {
            state.user = action.payload;
            state.token = action.payload?.token;
            state.refresh_token = action.payload?.refresh_token;
            state.message = "success";
            state.isLogged = true;
            state.projects = action.payload?.projects;
            state.accessRights = action.payload?.projects[0]["access_rights"]

            localStorage.setItem("it_user", JSON.stringify(state.user));
            localStorage.setItem("currency", action.payload?.projects[0]["currency"]);
            localStorage.setItem("it_access_rights", JSON.stringify(state.accessRights));

            localStorage.setItem("currency", action.payload?.projects[0]["currency"])

            // window.location.reload();
            window.location.href = '/projects';
        },
        initialize: (state, action) => {
            state.accessRights = {
                "project_admin_space": 3,
                "review_1": 3,
                "review_2": 3,
                "review_3": 3,
                "review_4": 3
            };

            localStorage.setItem("it_access_rights", JSON.stringify(state.accessRights));

            window.location.reload();
        },
        signInFailed: (state, action) => {
            state.user = {};
            state.message = action.payload;
            state.isLogged = false;
        },
        signOut: state => {
            state.user = {};
            state.token = "";
            state.refresh_token = "";
            state.isLogged = false;

            localStorage.removeItem("it_user");
        },
        refreshToken: (state, action) => {
            state.token = action.payload?.token;

            localStorage.setItem("refresh_token", state.token);
        }
    }
})

export const { signIn, signInFailed, signOut, refreshToken, initialize } = authSlice.actions;

export default authSlice.reducer;

export const userSignIn = (email: string, password: string) => async (dispatch: AppDispatch) => {
    try {
        await AuthService.api.post('/users/signin', JSON.stringify({email: email, password: password}))
            .then((response) => {
                dispatch(signIn(response.data["data"]))
            })
    } catch (e) {
        dispatch(signInFailed("error on request"))
    }
}

export const userSignUp = (email: string, name: string, surname: string, password: string) => async (dispatch: AppDispatch) => {
    try {
        await AuthService.api.post('/users/signup', JSON.stringify({email: email, name: name, surname: surname, password: password}))
            .then((response) => {
                window.location.href = "/";
            })
    } catch (e) {
        dispatch(signInFailed("error on request"))
    }
}

export const userRefreshToken = () => async (dispatch: AppDispatch) => {
    try {
        await AuthService.api.get('/users/refresh-token')
            .then((response) => {
                dispatch(refreshToken(response.data["data"]))
            })
    } catch (e) {
        dispatch(signInFailed("error on request"))
    }
}

export const initializeApp = () => async (dispatch: AppDispatch) => {
    try {
        await dispatch(initialize('lala'));
    } catch (e) {
    }
}