import { FC, useEffect, useRef } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  Divider,
  Button,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText
} from '@material-ui/core';
import { InputField } from '../input-field';
import { useDispatch, useSelector } from "react-redux";
import { ConfirmationDialog } from '../confirmation-dialog';
import { movePost, resetMoveStatus, updateField } from '../../slices/moveSlice';
import { review4GetAll } from '../../slices/review4Slice';
import { review3GetAll } from '../../slices/review3Slice';
import { unknownGetAll } from '../../slices/unknownSlice';
import { RootState } from '../../store';
import { useLocation } from 'react-router-dom';
import { getActiveElement } from '../../utils/active-element';
import { WizardSimpleCosting } from './move-wizard-simple-costing';
import { useTranslation } from 'react-i18next';
import { InfoPop } from '../info-pop';
import { openMoveDialogSimpleCosting } from 'src/slices/moveSliceSimpleCosting';
import { ChevronLeft as ChevronLeftIcon } from '../../icons/chevron-left';
import { ChevronRight as ChevronRightIcon } from '../../icons/chevron-right';

interface MoveDialogSimpleCostingProps {
  open: boolean;
  onClose: () => void;
}

export const MoveDialogSimpleCosting: FC<MoveDialogSimpleCostingProps> = (props) => {
  const { t } = useTranslation();
  const { open, onClose, ...other } = props;
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const move = useSelector((state: RootState) => state.moveSimpleCosting);
  const dispatch = useDispatch();
  const { moveIsLoading, moveStatus, moveNextAction } = useSelector((state: RootState) => state.move);

  const { moves } = useSelector((state: RootState) => state.review4);

  const [height, setHeight] = useState(0)
  const mainGridRef = useRef(null)
  const [refVisible, setRefVisible] = useState(false)

  useEffect(() => {
    if (!refVisible) {
      return
    }
    setHeight(mainGridRef.current.clientHeight)
  }, [refVisible, showDetails, mainGridRef?.current?.clientHeight])


  const location = useLocation();
  const activeElement = getActiveElement(location.pathname);

  useEffect(() => {
    console.log('store status', moveIsLoading);
    if (moveStatus === 'success') {
      toast.success('Move created');
      dispatch(review4GetAll());
      dispatch(review3GetAll());
      dispatch(unknownGetAll());
      dispatch(resetMoveStatus());
      if (moveNextAction === 'close') {
        onClose();
      }
    }
  }, [moveStatus]);

  const handleSubmit = () => {
    console.log('Submit and quit');
    let values = {
      name: move.name,
      comment: move.comment,
      selectedForActionPlan: move.selectedForActionPlan,
      launchPlan: move.launchPlan,
      reduceUnknownPlan: move.reduceUnknownPlan,
      decisionForActionPlan: move.decisionForActionPlan,
      concerning: move.concerning,
      collateralEffect: move.collateralEffect,
      startDate: move.startDate,
      finishDate: move.finishDate,
      estimatedCost: move.estimatedCost,
      userIdeaId: move.userIdeaId,
      descriptionOfTheMove: move.descriptionOfTheMove,
      kpi: move.kpi,
      goalDate: move.goalDate,
      descriptionOfTheGoal: move.descriptionOfTheGoal,
      costing: move.costing,
      detailedImpact: move.detailedImpact
    };
    console.log('new stakeholder', values);
    dispatch(movePost('close', values, move.moveId));
  };

  return (
    <Dialog
      maxWidth="xl"
      fullWidth
      onClose={() => { setOpenConfirmationDialog(true) }}
      open={open}
      // TransitionProps={{
      //   onExited: () => formik.resetForm()
      // }}
      PaperProps={{
        style: { borderRadius: 25 }
      }}
      sx={{ minHeight: '80vh'}}
      {...other}
    >
      {/* <DialogTitle>
        {(move.moveId !== null && move.moveId !== undefined) ? `${t('update_move')} : ${move.name}` : t('create_move')}
        <div style={{ display: 'inline-block'}}>
          <InfoPop
            infoElementName={t('moves')}
            shortText='What is a Tactical Move?'
            position='right'
            longText='A Tactical Move is an action taken to reduce Unknowns or to implement the Solution/product. A Move can be selected to contribute to the Action Plan, to the Implementation Plan or to the Plan to reduce Unknowns. For each Move you should clarify its objective/purpose, its timeline, its cost, the resource requirements, its impact on Stakeholders, and potential collateral effects'
          />
        </div>
      </DialogTitle> */}
      <DialogContent sx={{ padding: 0, backgroundColor: "background.default" }}>
        <Grid
          container
          spacing={2}
        // my={2}
        >
          <Grid item xs={12} sx={{ p: 0, backgroundColor: "background.default" }}>
            <Grid container spacing={2} padding={4} sx={{ overflowY: "scroll" }} maxHeight={'80vh'} ref={el => { mainGridRef.current = el; setRefVisible(!!el); }}>
              <Grid item xs={12}>
                <Typography variant="h5" color={'success.main'}>
                  {(move.moveId !== null && move.moveId !== undefined) ? `${t('update_move')} : ${move.name}` : t('create_move')}
                </Typography>
              </Grid>
              {/* <Grid item xs={12} textAlign="center">
                <Button
                  onClick={() => setShowDetails(!showDetails)}
                  type="button"
                  color='success'
                >
                  {showDetails === true ? t('hide_details') : t('show_details')}
                </Button>
              </Grid> */}
              {/* {showDetails === true ? (
                <Grid item xs={12}>
                  <Wizard data={move} />
                </Grid>
              ) : (<></>)} */}
              <Grid item xs={12}>
                <WizardSimpleCosting data={move} />
              </Grid>
              <Grid><Divider /></Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  color="success"
                  size="small"
                  onClick={onClose}
                  variant="outlined"
                  sx={{ mx: 1 }}
                >
                  Cancel
                </Button>
                <Button
                  color="success"
                  size="small"
                  disabled={(move.moveIsLoading || !move.isEditabled || (move.name.length < 3))}
                  onClick={() => { handleSubmit(); }}
                  variant="contained"
                  sx={{ mx: 1 }}
                >
                  {(move.moveId !== null && move.moveId !== undefined) ? t('update') : t('create')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      {/* <DialogActions>
        <Button
          color="primary"
          size="small"
          onClick={onClose}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          color="success"
          size="small"
          disabled={(move.moveIsLoading || !move.isEditabled || (move.name.length < 3))}
          onClick={() => { handleSubmit(); }}
          variant="contained"
        >
          {(move.moveId !== null && move.moveId !== undefined) ? t('update') : t('create')}
        </Button>
      </DialogActions> */}
      <ConfirmationDialog
        message={t('do_you_want_to_close_without_saving')}
        onCancel={() => { setOpenConfirmationDialog(false) }}
        onConfirm={() => {
          setOpenConfirmationDialog(false);
          onClose();
        }}
        open={openConfirmationDialog}
        title={t('confirmation_title')}
        variant='warning'
      />
    </Dialog>
  );
};

MoveDialogSimpleCosting.defaultProps = {
  open: false
};

MoveDialogSimpleCosting.propTypes = {
  // @ts-ignore
  open: PropTypes.bool,
  onClose: PropTypes.func
};
