import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
  Skeleton,
  Divider,
  FormGroup, FormControlLabel,
  Switch,
  OutlinedInput,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import LoadingButton from '@material-ui/lab/LoadingButton';
import 'react-loading-skeleton/dist/skeleton.css'
import { InputField } from '../input-field';
import { DateField } from '../date-field';
import { decisions, moveConcernings } from '../../static-data';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../store';
import { useDispatch, useSelector } from "react-redux";
import { updateField } from '../../slices/moveSliceSimpleCosting';
import { useAuth } from '../../hooks/use-auth';


export const WizardSimpleCostingDetails: FC = () => {
  const { t } = useTranslation();
  const { activeProject } = useAuth();

  const { costing } = useSelector((state: RootState) => state.moveSimpleCosting);
  const dispatch = useDispatch();

  const [localeCosting, setLocaleCosting] = useState(costing);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [total, setTotal] = useState(0);

  const currency = localStorage.getItem('currency');

  const getTotal = () => {
    let tot = 0
    Object.keys(localeCosting).map((c) => {
      let amountPerUnit = '0';
      if (localeCosting[c]['amountPerUnit'] !== '') {
        amountPerUnit = localeCosting[c]['amountPerUnit'];
      }
      if (localeCosting[c]['units'] === null) {
        tot += parseInt(amountPerUnit, 10)
      } else {
        tot += parseInt(localeCosting[c]['units'], 10) * parseInt(amountPerUnit, 10)
      }
    });
    setTotal(tot);
  }

  const getSubTotal = (nb, unit) => {
    let amountPerUnit = '0';
    if (unit !== '') {
      amountPerUnit = unit;
    }
    if (nb === null) {
      return parseInt(amountPerUnit, 10)
    } else {
      return parseInt(nb, 10) * parseInt(amountPerUnit, 10)
    }
  }


  useEffect(() => {
    getTotal();
  }, [localeCosting]);

  const onChangeField = (element: string, field: string, value: string) => {
    const newCosting = JSON.parse(JSON.stringify(localeCosting));
    // const newCosting = {...localeCosting};
    newCosting[element][field] = value;
    setLocaleCosting(newCosting);
    dispatch(updateField({ field: 'costing', value: newCosting }));
  };

  return (
    <Card
      // variant="outlined"
      sx={{ backgroundColor: 'background.default' }}
    // {...props}
    >
      <CardContent>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            xs={12}
          >
            <TableContainer sx={{ borderRadius: '8px', boxShadow: 7, }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell align='right'>Unit cost ({activeProject.currency})</TableCell>
                    <TableCell align='center'>Quantity</TableCell>
                    <TableCell align='right'>{t('total')} ({activeProject.currency})</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(localeCosting).map((c) => (
                    <TableRow>
                      <TableCell width={'50%'}>{t(c)}</TableCell>
                      <TableCell width={'20%'}>
                        {localeCosting[c]['units'] !== null ? (
                          <InputField
                            value={localeCosting[c]['units']}
                            onChange={(e) => onChangeField(c, 'units', e.target.value)}
                            // onBlur={onBlurField}
                            fullWidth
                            type="number"
                            InputProps={{
                              endAdornment: (<InputAdornment position="end">{c === 'space' ? 'm2' : 'hours'}&nbsp;&nbsp;</InputAdornment>),
                            }}
                            sx={{ textAlign: 'right' }}
                          />
                        ) : (<></>)}
                      </TableCell>
                      <TableCell width={'15%'}>
                        {localeCosting[c]['amountPerUnit'] !== null ? (
                          <InputField
                            value={localeCosting[c]['amountPerUnit']}
                            onChange={(e) => onChangeField(c, 'amountPerUnit', e.target.value)}
                            // onBlur={onBlurField}
                            fullWidth
                            type="number"
                            sx={{ textAlign: 'center' }}
                          />
                        ) : (<></>)}
                      </TableCell>
                      <TableCell align='right' width={'15%'}>
                        <b>{getSubTotal(localeCosting[c]['units'], localeCosting[c]['amountPerUnit'])}</b>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell align='right'>
                      <Typography variant="h6" color="success">
                        {t('total')} ({activeProject.currency})
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="h6" color="success">
                        {total} {currency}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};