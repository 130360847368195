import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  Divider,
  Button
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import type { SxProps } from '@material-ui/system';
import { usePopover } from '../hooks/use-popover';
import { Selector as SelectorIcon } from '../icons/selector';
import { useAuth } from '../hooks/use-auth';
import { projectUpdateActive } from '../api/project';
import { useTranslation } from 'react-i18next';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faListUl } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Organization {
  id: string;
  name: string;
}

interface ProjectPopoverProps {
  currentOrganization: Organization;
  onOrganizationChange: (organizationId: string) => void;
  organizations: Organization[];
  sx?: SxProps;
}

export const ProjectPopover: FC<ProjectPopoverProps> = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { currentOrganization, organizations, onOrganizationChange, sx, ...other } = props;
  const theme = useTheme();
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const { projects, activeProject } = useAuth();
  console.log('popover', projects, activeProject);

  const handleProjectChange = async (projectId: string) => {
    // Update active project
    const status = await projectUpdateActive({id: projectId})
    if(status === 'success') {
      // if(location.pathname.includes('/review-1/customer-fit/')) {
      //   window.location.href = '/'
      // } else {
      //   document.location.reload()
      // }
      window.location.href = '/';
    } else {
      console.log('error');
    }
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        size="small"
        ref={anchorRef}
        startIcon={<FontAwesomeIcon icon={faListUl as IconProp} size="xs" style={{ color: theme.palette.primary.main }} />}
        sx={{
          borderRadius: 1,
          display: 'flex',
          // p: 1,
          pl: 2,
          width: 250,
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          boxShadow: 7,
          ...sx
        }}
        {...other}
      >
        <Typography
          color="textSecondary"
          sx={{
            color: theme.palette.primary.main,
            mr: 3
          }}
          variant="subtitle2"
        >
          {activeProject?.name}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <SelectorIcon fontSize="small" style={{ color: theme.palette.primary.main }} />
      </Button>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 250, mt: 0.5, backgroundColor: 'background.default' }
        }}
      >
        <List>
          {projects.map((project) => (
            <ListItem
              key={project.id}
              button
              selected={project.id === activeProject.id}
              onClick={() => handleProjectChange(project.id)}
              dense
              sx={{
                '&.Mui-selected': {
                  backgroundColor: 'primary.main',
                  color: 'primary.contrastText',
                },
                '&.Mui-selected:hover': {
                  backgroundColor: 'primary.dark'
                }
              }}
            >
              <ListItemText primary={project?.name} />
            </ListItem>
          ))}
          <Divider />
          <ListItem
            button
            component={RouterLink}
            to="/projects"
          >
            <ListItemText primary={t('view_all')} style={{ alignItems: 'center' }} />
          </ListItem>
          <Divider />
          <ListItem
            button
            component={RouterLink}
            to="/projects"
            sx={{
              padding: 0,
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
              ':hover': {
                backgroundColor: 'primary.dark',
                color: 'primary.contrastText'
              }
            }}
          >
            <Button
              sx={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
                ':hover': {
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                }
              }}
              size="small"
              // startIcon={<PlusIcon />}
              variant="contained"
              component={RouterLink}
              to="/projects/new"
              fullWidth
            >
              {t('new_project')}
            </Button>
            {/* <ListItemText primary={t('new_project')} /> */}
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

ProjectPopover.propTypes = {
  // @ts-ignore
  currentOrganization: PropTypes.object.isRequired,
  onOrganizationChange: PropTypes.func,
  organizations: PropTypes.array.isRequired,
  sx: PropTypes.object
};
