import { FC, useEffect } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Divider,
  FormGroup,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { InputField } from '../input-field';
import { ConfirmationDialog }  from '../confirmation-dialog';
import { commentPost, resetCommentStatus } from '../../slices/commentSlice';
import { RootState } from '../../store';
import { useLocation } from 'react-router-dom';
import { getActiveElement } from '../../utils/active-element';
import { useTranslation } from 'react-i18next';

interface CommentDialogProps {
  open: boolean;
  onClose: () => void;
}

export const CommentDialog: FC<CommentDialogProps> = (props) => {
  const { t } = useTranslation();
  const { open, onClose, ...other } = props;
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const comment = useSelector((state: RootState) => state.comment);
  const dispatch = useDispatch();

  const location = useLocation();
  const activeElement = getActiveElement(location.pathname);

  let initialValues = {
    message: '',
    url: '',
    isShared: false,
    submit: null
  }

  if ((comment.commentId !== null && comment.commentId !== undefined) || comment.isDuplicated === true) {
    initialValues.message = comment.message;
    initialValues.url = comment.url;
    initialValues.isShared = comment.isShared;
  }

  useEffect(() => {
    if (comment.commentStatus === 'success') {
      toast.success('Comment created');
      formik.resetForm();
      formik.setStatus({ success: true });
      formik.setSubmitting(false);
      dispatch(resetCommentStatus());
      if (comment.commentNextAction === 'close') {
        onClose();
      }
    } else {
      formik.setStatus({ success: false });
      formik.setErrors({ submit: comment.commentStatus });
      formik.setSubmitting(false);
    }
  
  }, [comment.commentStatus]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      message: Yup.string().min(3).required('Message is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        // dispatch(review1PostCustomer(values));
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  const handleIsShared = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('isShared', event.target.checked);
  };

  const handleSubmitAndQuit = () => {
    dispatch(commentPost('close', {...formik.values, ...activeElement}));
  };

  return (
    <Dialog
      // maxWidth="md"
      fullWidth
      onClose={() => {setOpenConfirmationDialog(true)}}
      open={open}
      TransitionProps={{
        onExited: () => formik.resetForm()
      }}
      {...other}
    >
      <DialogTitle sx={{ backgroundColor: "background.default" }}>
        {t('create_comment')}
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "background.default" }}>
        <Grid
          container
          spacing={2}
          my={2}
        >
          <Grid
            item
            xs={12}
          >
            <InputField
              error={Boolean(formik.touched.message && formik.errors.message)}
              fullWidth
              helperText={formik.touched.message && formik.errors.message}
              label={t('message')}
              name="message"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.message}
              multiline
              rows={4}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <InputField
              error={Boolean(formik.touched.url && formik.errors.url)}
              fullWidth
              helperText={formik.touched.url && formik.errors.url}
              label={t('url')}
              name="url"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.url}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FormGroup>
              <FormControlLabel control={<Switch checked={formik.values.isShared} onChange={handleIsShared} />} label={t('share_with_team')} />
            </FormGroup>
          </Grid>
          {formik.errors.submit && (
            <Grid
              item
              xs={12}
            >
              <FormHelperText error>
                {formik.errors.submit}
              </FormHelperText>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ backgroundColor: "background.default" }}>
        <Button
          color="primary"
          size="small"
          onClick={onClose}
          variant="outlined"
          fullWidth
        >
          {t('cancel')}
        </Button>
        <Button
          color="primary"
          size="small"
          // disabled={commentIsLoading}
          // disabled={!(formik.isValid && formik.dirty) || comment.commentIsLoading}
          disabled={(comment.commentIsLoading || !comment.isEditabled)}
          onClick={() => { handleSubmitAndQuit(); }}
          variant="contained"
          fullWidth
        >
          {t('create')}
        </Button>
      </DialogActions>
      <ConfirmationDialog
        message={t('do_you_want_to_close_without_saving')}
        onCancel={() => {setOpenConfirmationDialog(false)}}
        onConfirm={() => {
          setOpenConfirmationDialog(false);
          onClose();
        }}
        open={openConfirmationDialog}
        title={t('confirmation_title')}
        variant='warning'
      />
    </Dialog>
  );
};

CommentDialog.defaultProps = {
  open: false
};

CommentDialog.propTypes = {
  // @ts-ignore
  open: PropTypes.bool,
  onClose: PropTypes.func
};
