import * as React from "react";
import { useState } from "react";
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  Card,
} from "@material-ui/core";
import PropTypes from 'prop-types';
import { WizardProfile } from "./stakeholder-wizard-profile";
import { WizardLever } from "./stakeholder-wizard-lever";
import { WizardInfluencing } from './stakeholder-wizard-influencing';
import { projectCreate } from "../../../api/project";
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../store';
import { useDispatch, useSelector } from "react-redux";

interface WizardProps {
  activeId: string;
}


const steps = ["profile", "lever", "influencing"];

export const Wizard: React.FC<WizardProps> = (props) => {
  const { t } = useTranslation();
  const { activeId } = props
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} nonLinear>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          // if (isStepSkipped(index)) {
          //   stepProps.completed = false;
          // }
          stepProps.completed = false;
          return (
            <Step key={label} {...stepProps} onClick={() => setActiveStep(index)} sx={{cursor: 'pointer', '& .MuiStepIcon-root .Mui-active': { color: '#8F16C8'}}}>
              <StepLabel {...labelProps} sx={{cursor: 'pointer', '& .MuiStepIcon-root .Mui-active': { color: '#8F16C8'} }}>{t(label)}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            {t('all_steps_completed')}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleBack}>{t('back')}</Button>
            {/* <Button disabled={!initialValues.isEditabled} onClick={handleOnConfirm}>{initialValues.isDuplicated === false ? t('update_stakeholder') : t('create_stakeholder')}</Button> */}
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ pt: 2 }}>
            <Card>
              {activeStep === 0 && (
                <WizardProfile />
              )}
              {activeStep === 1 && (
                <WizardLever />
              )}
              {activeStep === 2 && (
                <WizardInfluencing
                  activeId={activeId}
                />
              )}
            </Card>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            {activeStep > 0 && (
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                {`< ${t('back')}`}
              </Button>
            )}
            <Box sx={{ flex: "1 1 auto" }} />
            {activeStep < steps.length - 1 && (
              <Button onClick={handleNext}>
                {`${t('next_step')} >`}
              </Button>
            )}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

Wizard.propTypes = {
  activeId: PropTypes.string,
};
