import { FC, useState, useEffect } from "react";
import Proptypes from "prop-types";
import {
  Typography,
  Grid,
  Button,
  Popover,
  Divider,
  ButtonGroup,
} from "@material-ui/core";
import { InputField } from './input-field';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../store';
import { getLists } from '../slices/listSlice'
import { PndSelector } from "./review-1/pnd/pnd-selector";

interface PopoverListProps {
  type: string;
  element: any;
  disabled: boolean;
  onBlurInput: (element: any) => void;
}

export const PopoverList: FC<PopoverListProps> = (props) => {
  const { type, element, disabled, onBlurInput } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [activeElement, setActiveElement] = useState({
    description: '',
    move: '',
    type: 1 as 1 | 2 | 3,
    id: null
  });

  const [activeAspirations, setActiveAspirations] = useState([]);
  const [activeResistances, setActiveResistances] = useState([]);

  const { aspirations, resistances } = useSelector((state: RootState) => state.list);
  const dispatch = useDispatch();

  useEffect(() => {
    if (element) {
      setActiveElement(element);
    }
  }, [element]);

  useEffect(() => {
    if (activeElement.description !== '') {
      var newAspirations = aspirations.filter(el => el.description.toLowerCase().includes(activeElement.description.toLowerCase()));
      var newResistances = resistances.filter(el => el.description.toLowerCase().includes(activeElement.description.toLowerCase()));
      setActiveAspirations(newAspirations);
      setActiveResistances(newResistances);
    }
    
  }, [activeElement]);

  useEffect(() => {
    setActiveAspirations(aspirations);
    setActiveResistances(resistances);
  }, [aspirations, resistances]);

  useEffect(() => {
    dispatch(getLists());
    
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onBlurInput(activeElement);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button fullWidth onClick={handleClick} sx={{ padding: '0px 0px 0px 5px !important' }} disabled={props.disabled}>
        <span
          aria-describedby={id}
          style={{ flexGrow: 1, textAlign: "left", paddingTop: type === 'resistance' && '5px', paddingBottom: type === 'resistance' && '5px' }}
        >
          {activeElement.description === '' ? <i>Click to add element</i> : activeElement.description}
        </span>
        {type === 'aspiration' && (
          <PndSelector currentPnd={activeElement.type} disabled />
        )}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ '& .MuiPaper-root': { backgroundColor: 'background.default' } }}
      >
        <Grid p={1}>
          <Grid container width={500} spacing={1}>
            <Grid item xs={type === 'aspiration' ? 9 : 12}>
              <InputField
                fullWidth
                placeholder="Enter new element description"
                size="small"
                value={activeElement.description}
                onChange={(e) => setActiveElement({ ...activeElement, description: e.target.value, id: null })}
                disabled={props.disabled}
              />
            </Grid>
            {type === 'resistance' && (
              <Grid item xs={12}>
                <InputField
                  fullWidth
                  placeholder="Enter move to reduce resistance"
                  size="small"
                  value={activeElement.move}
                  onChange={(e) => setActiveElement({ ...activeElement, move: e.target.value, id: null })}
                  disabled={props.disabled}
                />
              </Grid>
            )}
            {type === 'aspiration' && (
              <Grid item xs={3}>
                <ButtonGroup aria-label="outlined primary button group" size="small" disabled={props.disabled}>
                  <Button
                    variant={activeElement.type === 1 ? "contained" : "outlined"}
                    onClick={() => setActiveElement({ ...activeElement, type: 1 })}
                  >
                    P
                  </Button>
                  <Button
                    variant={activeElement.type === 2 ? "contained" : "outlined"}
                    onClick={() => setActiveElement({ ...activeElement, type: 2 })}
                  >
                    N
                  </Button>
                  <Button
                    variant={activeElement.type === 3 ? "contained" : "outlined"}
                    onClick={() => setActiveElement({ ...activeElement, type: 3 })}
                  >
                    D
                  </Button>
                </ButtonGroup>
              </Grid>
            )}
          </Grid>
          <Grid p={0.5}>
            <Divider />
          </Grid>
          {/* <Grid container width={500} spacing={1}> */}
          {type === 'aspiration' ? (
            activeAspirations?.map(item => (
              <Grid container width={500} spacing={0.5}>
                <Grid item xs={9} sx={{ cursor: 'pointer' }} onClick={() => {
                  // setActiveElement({...activeElement, description: item.description, id: item.id, type: item.type});
                  onBlurInput({ description: item.description, id: item.id, type: item.type });
                  setAnchorEl(null);
                }}>
                  <Typography>{item.description}</Typography>
                </Grid>
                <Grid item textAlign={'right'} xs={3}>
                  {/* <span style={{ padding: 5, backgroundColor: 'red', borderRadius: 5 }}>{pnds[item.type]}</span> */}
                  <ButtonGroup aria-label="outlined primary button group" size="small" disabled>
                    <Button
                      variant={item.type === 1 ? "contained" : "outlined"}
                      // onClick={() => onChangeField(i, "type", field, 1)}
                      sx={{ fontSize: 8, py: 0.1 }}
                    >
                      P
                    </Button>
                    <Button
                      variant={item.type === 2 ? "contained" : "outlined"}
                      // onClick={() => onChangeField(i, "type", field, 2)}
                      sx={{ fontSize: 8, py: 0.1 }}
                    >
                      N
                    </Button>
                    <Button
                      variant={item.type === 3 ? "contained" : "outlined"}
                      // onClick={() => onChangeField(i, "type", field, 3)}
                      sx={{ fontSize: 8, py: 0.1 }}
                    >
                      D
                    </Button>
                  </ButtonGroup>
                </Grid>
                <Grid xs={12} item p={0.5}>
                  <Divider />
                </Grid>
              </Grid>
            )))
            : (
              activeResistances?.map(item => (
                <Grid container width={500} spacing={1}>
                  <Grid item xs={12} sx={{ cursor: 'pointer', mb: 2 }} onClick={() => {
                    // setActiveElement({...activeElement, description: item.description, id: item.id, type: item.type});
                    onBlurInput({ description: item.description, id: item.id, type: item.type });
                    setAnchorEl(null);
                  }}>
                    <Typography>{item.description}</Typography>
                  </Grid>
                </Grid>
              )
              ))}
          <Grid p={1}>
            <Divider />
          </Grid>
          <Grid p={1} container width={500} spacing={1}>
            <Grid item xs={12}>
              <Button
                size="small"
                variant="contained"
                fullWidth
                onClick={() => handleClose()}
              >{`Update ${type === 'aspiration' ? 'aspiration' : 'resistance'}`}</Button>
            </Grid>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

PopoverList.propTypes = {
  type: Proptypes.string,
  element: Proptypes.object.isRequired,
  disabled: Proptypes.bool,
  onBlurInput: Proptypes.func
};