import { createSlice } from "@reduxjs/toolkit";
import APIService from "../services/api.service";
import { AppDispatch } from "../store";
import axios from "axios";

const initialState = {
  review2: null,
  history: {
    object: null,
    field: null,
    data: [],
  },
  review2IsInitialized: false
};

export const review2Slice = createSlice({
  name: "review2",
  initialState,
  reducers: {
    getReview2: (state, action) => {
      state.review2 = action.payload;
      state.review2IsInitialized = true;
    },
    putReview2: (state, action) => {
      state.review2 = action.payload;
      state.review2.confidence_indice = Math.min(
        action.payload.activity_chain.confidence_indice,
        action.payload.definition.kiss_confidence_indice,
        action.payload.definition.terminators_confidence_indice,
        action.payload.economic_model.confidence_indice,
        action.payload.skill.confidence_indice,
        action.payload.benefit.confidence_indice
      );
    },
    putReview2ConfidenceIndice: (state, action) => {
      state.review2.confidence_indice = action.payload;
    },
    putReview2Details: (state, action) => {
      const cus = action.payload.data;
      const newData = {...state.review2};
      let key = action.payload.detailType;
      if (action.payload.detailType === 'economic-model') {
        key = 'economic_model'
      }
      if (action.payload.detailType === 'activity-chain') {
        key = 'activity_chain'
      }
      Object.keys(cus).forEach((k) => {
        newData[key][k] = cus[k];
      });
      state.review2 = newData;
      state.review2.confidence_indice = Math.min(
        newData.activity_chain.confidence_indice,
        newData.definition.kiss_confidence_indice,
        newData.definition.terminators_confidence_indice,
        newData.economic_model.confidence_indice,
        newData.skill.confidence_indice,
        newData.benefit.confidence_indice
      );
    },
  },
});

export const {
  getReview2,
  putReview2,
  putReview2Details,
  putReview2ConfidenceIndice
} = review2Slice.actions;

export default review2Slice.reducer;

export const review2GetAll = () => async (dispatch: AppDispatch) => {
    try {
      let activity_chain_q = APIService.get("/review-2/activity-chains")
      let definition_q = APIService.get("/review-2/definitions")
      let economic_model_q = APIService.get("/review-2/economic-models")
      let skill_q = APIService.get("/review-2/skills")
      let benefit_q = APIService.get("/review-2/benefits")

      axios.all([activity_chain_q, definition_q, economic_model_q, skill_q, benefit_q]).then(axios.spread((...response) => {
        dispatch(putReview2({
          'activity_chain': response[0].data.activity_chain,
          'definition': response[1].data.definition,
          'economic_model': response[2].data.economic_model,
          'skill': response[3].data.skill,
          'benefit': response[4].data.benefit,
        }))
      })).catch(e => {
        console.log(e)
      })

    } catch (e) {
      // dispatch(setCustomerError());
    }
  };

export const review2PutElement =
  (data: object) => async (dispatch: AppDispatch) => {
    try {
      // dispatch(setCustomersLoading());
      await APIService.put('/review-2/companies', data).then(
        (response) => {
          dispatch(putReview2(response.data.data.result));
        }
      );
    } catch (e) {
      // dispatch(setCustomerError());
    }
  };

export const review2PutDetails =
  (detailType: string, data: object) => async (dispatch: AppDispatch) => {
    try {
      // dispatch(setCustomersLoading());
      await APIService.put(`/review-2/${detailType}s`, data).then(
        (response) => {
          dispatch(putReview2Details({ detailType, data }));
        }
      );
    } catch (e) {
      // dispatch(setCustomerError());
    }
  };
