import { useEffect } from 'react';
import type { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import { Footer } from '../components/footer';
import { DashboardNavbar } from '../components/dashboard-navbar';
import { DashboardSidebar } from '../components/dashboard-sidebar';
import { LogSidebar } from '../components/log-sidebar';
import { useSettings } from '../contexts/settings-context';
import { CommentDialog } from '../components/quick-actions/comment-dialog';
import { unpinSidebar } from '../slices/logSlice';
import { closeCommentDialog } from '../slices/commentSlice';
import { closeDocumentDialog } from '../slices/documentSlice';
import { RootState } from '../store';
import { DocumentDialog } from '../components/quick-actions/document-dialog';
import { useAuth } from '../hooks/use-auth';
import { WelcomeDialog } from '../components/dialogs/welcome-dialog';

const DashboardLayoutRoot = styled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    paddingTop: 64,
    maxWidth: '100%'
  })
);

const DashboardLayoutContent = styled('div')(
  () => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxWidth: '100%',
  })
);

export const DashboardLayout: FC = () => {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { settings, saveSettings } = useSettings();
  const { logSidebarPinned } = useSelector((state: RootState) => state.log)
  const { commentDialogOpen } = useSelector((state: RootState) => state.comment)
  const { documentDialogOpen } = useSelector((state: RootState) => state.document)
  const { welcomeMessageRead} = useAuth();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   window.onbeforeunload = function() {return "Do you really want to leave our brilliant application?"};
  // }, []);

  const handlePinSidebar = (): void => {
    saveSettings({
      ...settings,
      pinSidebar: !settings.pinSidebar
    });
  };

  const handleOnPinLogSidebar = (): void => {
    dispatch(unpinSidebar());
  }

  return (
    <DashboardLayoutRoot sx={{backgroundColor: 'background.paper'}}>
      <DashboardNavbar />
      {!mdDown && (
        <DashboardSidebar
          onPin={handlePinSidebar}
          pinned={settings.pinSidebar}
        />
      )}
      <LogSidebar
        onPin={handleOnPinLogSidebar}
        pinned={logSidebarPinned}
      />
      <DashboardLayoutContent
        sx={{
          ml: {
            md: settings.pinSidebar ? '250px' : '73px'
          },
          mr: {
            md: logSidebarPinned ? '350px' : '0px'
          },
          backgroundColor: 'background.paper'
        }}
      >
        {/* <Box sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1 }}> */}
          {/* <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: 'absolute', bottom: 25, right: 25 }}
            icon={<SpeedDialIcon />}
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={() => {
                  console.log('dispatch')
                  dispatch(action.action);
                }}
              />
            ))}
          </SpeedDial> */}
        {/* </Box> */}
        <Outlet />
        <Footer />
        {/* <UnknownDialog open={unknownDialogOpen} onClose={() => dispatch(closeUnknownDialog())} /> */}
        {/* <FactorDialog open={factorDialogOpen} onClose={() => dispatch(closeFactorDialog())} /> */}
        {/* <IntellectualPropertyDialog open={intellectualPropertyDialogOpen} onClose={() => dispatch(closeIntellectualPropertyDialog())} /> */}
        <CommentDialog open={commentDialogOpen} onClose={() => dispatch(closeCommentDialog())} />
        <DocumentDialog open={documentDialogOpen} onClose={() => dispatch(closeDocumentDialog())} />
        {/* <MoveDialog open={moveDialogOpen} onClose={() => dispatch(closeMoveDialog())} /> */}
        {/* <StakeholderDialog open={stakeholderDialogOpen} onClose={() => dispatch(closeStakeholderDialog())} /> */}
        <WelcomeDialog open={!welcomeMessageRead} onClose={() => {}} />
      </DashboardLayoutContent>
    </DashboardLayoutRoot>
  );
};
