import { FC } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Switch,
  Divider
} from '@material-ui/core';
import 'react-loading-skeleton/dist/skeleton.css'
import { InputField } from '../input-field';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../store';
import { useDispatch, useSelector } from "react-redux";
import { updateField } from '../../slices/moveSlice';

export const WizardDetails: FC = () => {
  const { t } = useTranslation();

  const move = useSelector((state: RootState) => state.move);
  const dispatch = useDispatch();

  return (
    <Card
      // variant="outlined"
      sx={{ backgroundColor: 'background.default' }}
    // {...props}
    >
      <CardContent>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            xs={12}
          >
            <div>
              <Grid
                container
                spacing={2}
              >

                <Grid
                  item
                  xs={12}
                  md={12}
                >
                  Considered for the following Action plan to:
                </Grid>

                <Grid
                  // display={move.selectedForActionPlan === true ? 'block' : 'none'}
                  item
                  xs={12}
                  md={6}
                >
                  <Tooltip title={"The selection of Tactical Moves that are expected to deliver the Definition of Success "} placement={'bottom'} arrow>
                    <FormGroup style={{ marginTop: '20px' }}>
                      <FormControlLabel control={<Switch
                        checked={move.launchPlan}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            dispatch(updateField({ field: 'launchPlan', value: e.target.checked }));
                            dispatch(updateField({ field: 'reduceUnknownPlan', value: false }));
                          } else {
                            dispatch(updateField({ field: 'launchPlan', value: e.target.checked }));
                            dispatch(updateField({ field: 'reduceUnknownPlan', value: true }));
                          }
                        }}
                      />} label={t('selected_for_launch_plan')} />
                    </FormGroup>
                  </Tooltip>
                </Grid>

                <Grid
                  // display={move.selectedForActionPlan === true ? 'block' : 'none'}
                  item
                  xs={12}
                  md={6}
                >
                  <Tooltip title={"The selection of Tactical Moves for reducing Unknowns "} placement={'bottom'} arrow>
                    <FormGroup style={{ marginTop: '20px' }}>
                      <FormControlLabel control={<Switch
                        checked={move.reduceUnknownPlan}
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            dispatch(updateField({ field: 'reduceUnknownPlan', value: e.target.checked }));
                            dispatch(updateField({ field: 'launchPlan', value: false }));
                          } else {
                            dispatch(updateField({ field: 'reduceUnknownPlan', value: e.target.checked }));
                            dispatch(updateField({ field: 'launchPlan', value: true }));
                          }
                        }}
                      />} label={t('selected_for_reduce_unknown_plan')} />
                    </FormGroup>
                  </Tooltip>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid
                  item
                  xs={12}
                >
                  <InputField
                    // error={Boolean(formik.touched.kpi && formik.errors.kpi)}
                    fullWidth
                    // helperText={formik.touched.kpi && formik.errors.kpi}
                    label={t('kpi')}
                    name="kpi"
                    // onBlur={formik.handleBlur}
                    onChange={(e) => dispatch(updateField({ field: 'kpi', value: e.target.value }))}
                    value={move.kpi}
                    placeholder=""
                    multiline
                    rows={2}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                >
                  <InputField
                    // error={Boolean(formik.touched.descriptionOfTheGoal && formik.errors.descriptionOfTheGoal)}
                    fullWidth
                    // helperText={formik.touched.descriptionOfTheGoal && formik.errors.descriptionOfTheGoal}
                    label={'Goal that this Move must achieve (for GDPM)'}
                    name="descriptionOfTheGoal"
                    // onBlur={formik.handleBlur}
                    onChange={(e) => dispatch(updateField({ field: 'descriptionOfTheGoal', value: e.target.value }))}
                    value={move.descriptionOfTheGoal}
                    placeholder=""
                    multiline
                    rows={2}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                >
                  <InputField
                    // error={Boolean(formik.touched.goalDate && formik.errors.goalDate)}
                    fullWidth
                    // helperText={formik.touched.goalDate && formik.errors.goalDate}
                    label={'Goal due date (for GDPM)'}
                    name="goalDate"
                    // onBlur={formik.handleBlur}
                    onChange={(e) => dispatch(updateField({ field: 'goalDate', value: e.target.value }))}
                    value={move.goalDate}
                    placeholder=""
                    multiline
                    rows={2}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                >
                  <InputField
                    // error={Boolean(formik.touched.kpi && formik.errors.kpi)}
                    fullWidth
                    // helperText={formik.touched.kpi && formik.errors.kpi}
                    label={'Key Performance Indicators (KPIs) to verify that the goal has been reached'}
                    name="kpi"
                    // onBlur={formik.handleBlur}
                    onChange={(e) => dispatch(updateField({ field: 'kpi', value: e.target.value }))}
                    value={move.kpi}
                    placeholder=""
                    multiline
                    rows={2}
                  />
                </Grid>

                {/* {formik.errors.submit && (
                    <Grid
                      item
                      xs={12}
                    >
                      <FormHelperText error>
                        {formik.errors.submit}
                      </FormHelperText>
                    </Grid>
                  )} */}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};